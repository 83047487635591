div.watchscreen{
    background: url("../../assets/images/userspage-bg.png");
    /* padding: 130px 0 0; */
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 150px;

}
div.watchscreen div.wrapper{
    width: 90%;
    margin: 0 auto;
}
div.watchscreen div.wrapper div.watchscreenContainer{
    display: flex;
    padding: 50px;
    
}
div.watchscreen div.wrapper div.watchscreenContainer div.watchContainer{
    width: 100%;
    /* width: 90%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}
div.watchscreen div.wrapper div.watchscreenContainer div.watchContainer h1{
    color: #fff !important;
    /* margin-top: 20px; */
    margin-bottom: 20px;
    font-size: 25px;
    text-align: center;
   
}
div.watchscreen div.wrapper div.watchscreenContainer div.watchContainer div.descriptionCard{
   display: flex;
   background-color: #fff;
   border-radius: 30px;
   margin-bottom: 15px;
}
div.watchscreen div.wrapper div.watchscreenContainer div.watchContainer div.descriptionCard .firstchild{
   
 }
div.watchscreen div.wrapper div.watchscreenContainer div.watchContainer div.descriptionCard div.imageContainer{
   width: 100px;
   display: flex; 
   flex-direction: column;
   justify-content: center;
   align-items: center;
 }
 div.watchscreen div.wrapper div.watchscreenContainer div.watchContainer div.descriptionCard div.imageContainer img{
    display: block;
    width: 100%;
    margin-left: 50px;
  }
  div.watchscreen div.wrapper div.watchscreenContainer div.watchContainer div.descriptionCard div.skylogo{
    width: 135px;
    height: 100px;
  }
  div.watchscreen div.wrapper div.watchscreenContainer div.watchContainer div.descriptionCard div.skylogo img{
    display: block;
    width: 100%;
    margin-left: 50px;
  }
  div.watchscreen div.wrapper div.watchscreenContainer div.watchContainer div.descriptionCard div.infoContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 20px 20px 50px;
  }
  div.watchscreen div.wrapper div.watchscreenContainer div.watchContainer div.descriptionCard div.infoContainer a{
    color: #000 !important;
    font-size: 13px;
    font-weight: 600;
    text-decoration: underline;
  }
  div.watchscreen div.wrapper div.watchscreenContainer div.watchContainer div.descriptionCard div.infoContainer p{
    color: #000 !important;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
  }



div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer{
    /* width: 100%; */
    width: 65%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}
div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer h1{
    font-size: 25px;
    /* margin-top: 20px; */
    margin-bottom: 20px;
   
}
div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer a{
    font-size: 12px;
    color: #fff !important;
    border-bottom: 1px solid #fff;
}


div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer div.appstoreContainer{
    margin-top: 15px;   
    width: 35%; 
}
div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer div.appstoreContainer div.logoContainer{
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    background-color: #3b66af;
    border-radius: 30px;
}
div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer div.appstoreContainer div.logoContainer img{
    display: block;
    width: 60px;
    border-radius: 25px;
}
div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer div.appstoreContainer div.playstorecontainer{
    height: 13%;
    cursor: pointer;
    display: flex;
}
div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer div.appstoreContainer div.playstorecontainer img{
    display: block;
    width: 100px;
    height: 80px;
    border-radius: 25px;
    margin-top: -15px;


}
div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer div.appstoreContainer div.googletv{
    height: 13%;
    cursor: pointer;
    display: flex;
}
div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer div.appstoreContainer div.googletv img{
    display: block;
    width: 115px;
    height: 110px;
    border-radius: 25px;
    margin-top: -27px;
}

@media (max-width: 1400px){
    div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer div.appstoreContainer{  
        width: 45%; 
    }
}
@media all and (max-width:1200px){
    div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
   
    div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer div.appstoreContainer{
        margin-top: 40px;   
    }
}
@media (max-width: 980px){
    div.watchscreen div.wrapper{
        width: 90%;
        
    }
}
@media (max-width: 820px){
    div.watchscreen div.wrapper div.watchscreenContainer{
        display: block; 
        padding: 0px;  
    }
    div.watchscreen div.wrapper div.watchscreenContainer div.watchContainer h1{  
        margin-top: 20px;    
    }
    div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer div.appstoreContainer{  
        width: 32%; 
    }
    div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer div.appstoreContainer  div.playstorecontainer {
        height: 51px;
    }
    div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer div.appstoreContainer  div.playstorecontainer img{
        height: 92px;
        border-radius: 25px;
        margin-top: -20px;
    
    }
    div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer div.appstoreContainer  div.googletv{
        height: 51px;
        margin-bottom: 30px;
        
    }
    div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer div.appstoreContainer  div.googletv img{
        height: 110px;
        width: 130px;
        margin-top: -30px;
    
    }
   
   
}
@media all and (max-width:768px){
    div.watchscreen div.wrapper{
        width: 90%;   
    }
    div.watchscreen div.wrapper div.watchscreenContainer{
        display: block; 
        padding: 0px;  
    }
  
    div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer h1{
        margin-bottom: 5px;
       
    }
    div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer div.appstoreContainer{
        margin-top: 20px;    
    }
    div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer div.appstoreContainer div.logoContainer{
        margin-bottom: 20px;
    }
    div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer div.appstoreContainer  div.googletv img{
        height: 110px;
        width: 115px;
        margin-top: -30px;
    }
   
}
@media (max-width: 640px){
    div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer div.appstoreContainer{  
        width: 40%; 
    }
}
@media (max-width: 480px){
    div.watchscreen .wrapper .watchscreenContainer .iconButtonContainer div.appstoreContainer{  
        width: 50%; 
    }
}