#register { 
    padding: 150px 0 0;
    color: #fff;
}
#register h1.heading { 
    text-align: center;
}
#register div.registerContainer { 
    background: url("../../../assets/images/userspage-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0 111px;
    margin-top: 30px;
}
#register div.registerContainer div.wrapper { 
    display: flex;
    justify-content: center;
}
#register div.registerContainer div.wrapper div.leftContainer { 
    width: 21%;
    margin-right: 60px;
}
#register div.registerContainer div.wrapper div.leftContainer div.imageContainer { 
    width: 91%;
}
#register div.registerContainer div.wrapper div.leftContainer div.imageContainer img { 
    display: block;
    width: 100%;
}
#register div.registerContainer div.wrapper div.rightContainer { 
    width: 55%;
}
#register div.registerContainer div.wrapper div.rightContainer h1 { 
    text-align: center;
}
#register div.registerContainer div.wrapper div.rightContainer form { 
    margin-top: 30px;
    width: 100%;
}
#register div.registerContainer div.wrapper div.rightContainer form div.form-group { 
    display: flex;
    justify-content: space-between;
    width: 100%;
}
#register div.registerContainer div.wrapper div.rightContainer form div.form-group div.oneByOne { 
    width: 48%;
    margin-bottom: 20px;
}
#register div.registerContainer div.wrapper div.rightContainer form div.form-group label { 
    display: block;
    margin-bottom: 10px;
}
#register div.registerContainer div.wrapper div.rightContainer form div.form-group div.oneByOne input { 
    width: 100%;
    padding: 12px 8px;
    border-radius: 5px;
    border: none;
    outline: none;
}
#register div.registerContainer div.wrapper div.rightContainer form div.form-group div.oneByOne input:focus { 
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);

}
#register div.registerContainer div.wrapper div.rightContainer form div.form-group div.oneByOne select:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);

}
#register div.registerContainer div.wrapper div.rightContainer form div.form-group div.oneByOne select {
    width: 100%;
    padding: 12px 8px;
    border-radius: 5px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    font-size: 16px;
    color: rgb(34, 34, 34);
}
#register div.registerContainer div.wrapper div.rightContainer form div.form-group div.oneByOne select::-ms-expand  {
    display: none;
    
}
#register div.registerContainer div.wrapper div.rightContainer form div.form-group div.oneByOne button {
    background-color: #000;
    padding: 6px 41px;
    border: 2px solid #fff;
    border-radius: 30px;
    color: #fff;
    font-size: 18px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    width: 54%;
    max-height: 43px;
}
#register div.registerContainer div.wrapper div.rightContainer form div.form-group div.oneByOne button:hover {
    background-color: #213a64;
}
#register div.registerContainer div.wrapper div.rightContainer form div.form-group:last-child {
    display: flex;
}
#register div.registerContainer div.wrapper div.rightContainer form div.form-group:last-child div.oneByOne {
    display: flex;
    justify-content: center;
    width: 100%;
}
#register div.registerContainer div.wrapper div.rightContainer form div.form-group:last-child div.oneByOne p {
   max-width: 300px;
}
#register div.registerContainer div.wrapper div.rightContainer form div.form-group:last-child div.oneByOne p span {
    font-weight: 600;
 }
#register div.registerContainer div.wrapper div.rightContainer p { 

}
#register div.registerContainer div.wrapper div.rightContainer p span { 
    font-weight: 600;
}


/* Responsive section */

@media all and (max-width:1280px){
    #register div.registerContainer {
        background-position-x: -132px;
    }
}
@media all and (max-width:980px){
    #register div.registerContainer {
        background-position-x: -496px;
    }
    #register div.registerContainer div.wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    #register div.registerContainer div.wrapper div.leftContainer {
        width: 39%;
        margin: 0 auto 100px;
    }
    #register div.registerContainer div.wrapper div.rightContainer {
        width: 77%;
        margin: 0 auto;
    }
    #register div.registerContainer div.wrapper div.leftContainer div.imageContainer {
        width: 100%;
    }
}
@media all and (max-width:768px){
    #register div.registerContainer div.wrapper div.rightContainer {
        width: 100%;
        margin: 0 auto;
    }
    #register div.registerContainer div.wrapper div.leftContainer div.imageContainer {
        width: 100%;
    }
}
@media all and (max-width:640px){
    #register div.registerContainer {
        background-position-x: -880px;
    }
    #register div.registerContainer div.wrapper div.leftContainer {
        width: 61%;
    }
    #register div.registerContainer div.wrapper div.rightContainer form div.form-group {
        flex-direction: column;
    }
    #register div.registerContainer div.wrapper div.rightContainer form div.form-group div.oneByOne {
        width: 100%;
    }
    #register div.registerContainer div.wrapper div.rightContainer form div.form-group:last-child div.oneByOne p {
        max-width: 100%;
    }
    #register div.registerContainer div.wrapper div.rightContainer form div.form-group:last-child div.oneByOne + div.oneByOne {
        justify-content: left;
    }
    #register div.registerContainer div.wrapper div.rightContainer form div.form-group:last-child div.oneByOne p {
        max-width: 94%;
    }
    #register div.registerContainer div.wrapper div.rightContainer form div.form-group div.oneByOne button {
        width: 40%;
    }
    #register div.registerContainer div.wrapper div.rightContainer form div.form-group div.oneByOne button {
        background-color: #000;
        padding: 6px 0;
    }

#register div.registerContainer div.wrapper div.leftContainer div.imageContainer {
    width: 100%;
}
}
@media all and (max-width:480px){
    #register div.registerContainer div.wrapper div.leftContainer {
        width: 72%;
    }
    #register div.registerContainer div.wrapper div.leftContainer div.imageContainer {
        width: 100%;
    }
    #prayer div.content div.MessageContainer form div.submit button {
        width: 44%;
    }
    
}
@media all and (max-width:360px){
    
}
@media all and (max-width:320px){
    
}