.no-active {
    display: none;
}
#prayer { 
    padding: 150px 0 0;
    color: #fff;;
}
#prayer h1.heading { 
    text-align: center;
    margin-bottom: 50px;
}
#prayer div.adBannerContainer{
    width: 100%;
    display: block;
}
#prayer div.adBannerContainer img {
    width: 100%;
    display: block;
}
#prayer div.content { 
    background: url("../../assets/images/userspage-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 80px 0 300px;
    text-align: center;
    margin-top: 40px;
}
#prayer div.content h3 { 
    font-size: 21px;
    margin-bottom: 10px;
}
#prayer div.content p.description { 
    max-width: 41%;
    margin: 0 auto 30px;
    line-height: 1.6em;
    font-size: 16px;
}
#prayer div.content div.MessageContainer { 
    width: 41%;
    margin: 0 auto;
}
#prayer div.content div.MessageContainer form { 
    width: 100%;
}
#prayer div.content div.MessageContainer form div.textArea { 
    width: 100%;
}
#prayer div.content div.MessageContainer form div.input { 
    width: 100%;
}
#prayer div.content div.MessageContainer form div.textArea textarea { 
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    resize: vertical;
}
#prayer div.content div.MessageContainer form div.textArea input { 
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    resize: vertical;
}
#prayer div.content div.MessageContainer form div.textArea textarea:focus { 
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);

}
#prayer div.content div.MessageContainer form div.textAreaDetails { 
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
#prayer div.content div.MessageContainer form div.textAreaDetails div.left { 

}
#prayer div.content div.MessageContainer form div.textAreaDetails div.left p.imageName { 
        margin-bottom: 10px;
        text-align: left;
}
#prayer div.content div.MessageContainer form div.textAreaDetails div.left span { 
    
}
#prayer div.content div.MessageContainer form div.textAreaDetails div.right { 

}
#prayer div.content div.MessageContainer form div.textAreaDetails div.right label {

}
#prayer div.content div.MessageContainer form div.textAreaDetails div.right label div.button {
    background-color: var(--button-color);
    padding: 4px 21px;
    border: 2px solid #fff;
    border-radius: 30px;
    color: #fff;
    font-size: 18px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
#prayer div.content div.MessageContainer form div.textAreaDetails div.right label div.button:hover {
    background-color: #213a64;
}
#prayer div.content div.MessageContainer form div.textAreaDetails div.right label div.button img {
    width: 25px;
}
#prayer div.content div.MessageContainer form div.textAreaDetails div.right input { 
    display: none;
    visibility: none;
}
#prayer div.content div.MessageContainer form div.captcha { 
display: block;
background: red;
}
#prayer div.content div.MessageContainer form div.submit { 

}
#prayer div.content div.MessageContainer form div.submit button { 
    background-color: var(--button-color);
    padding: 6px 21px;
    border: 2px solid #fff;
    border-radius: 30px;
    color: #fff;
    font-size: 16px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    width: 12rem;
}
#prayer div.content div.MessageContainer form div.submit button:hover { 
    background-color: #213a64;
}


/* Responsive section  */

@media all and (max-width:1280px){
    
    #prayer div.content p.description {
        max-width: 63%;
    }
    
    #prayer div.content div.MessageContainer form div.submit button {
        font-size: 15px;
    }
    #prayer div.content div.MessageContainer {
        width: 50%;
    }
}

@media all and (max-width:980px){
    /* #prayer div.content {
        background-position-x: -220px;
    } */
    #prayer div.content p.description {
        max-width: 73%;
    }
    #prayer div.content div.MessageContainer {
        width: 75%;
    }
    
}
@media all and (max-width:768px){
    /* #prayer div.content {
        background-position-x: -292px;
    } */
    #prayer div.content p.description {
        max-width: 90%;
    }
    #prayer div.content div.MessageContainer {
        width: 89%;
    }
}
@media all and (max-width:640px){
    /* #prayer div.content {
        background-position-x: -409px;
    } */
    #prayer div.content p.description {
        max-width: 100%;
    }
    #prayer div.content div.MessageContainer {
        width: 100%;
    }
    #prayer div.content div.MessageContainer form div.submit button {
        width: 40%;
    }
}
@media all and (max-width:480px){
    /* #prayer div.content {
        background-position-x: -551px;
    } */
    #prayer div.content div.MessageContainer form div.submit button {
        width: 12rem !important;
    }
}
@media all and (max-width:360px){
    
}
@media all and (max-width:320px){
    
}
