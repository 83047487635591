.no-active {
    display: none;
}
#contact-us { 
    padding: 150px 0 0;
    color: #fff;;
}
#contact-us h1.heading { 
    text-align: center;
    margin-bottom: 50px;
}
#contact-us div.content { 
    background: url("../../assets/images/userspage-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 104px 0 300px;
    text-align: center;
    margin-top: 40px;
}
#contact-us div.content h3 { 
    font-size: 21px;
    margin-bottom: 10px;
}
#contact-us div.content p.description { 
    max-width: 41%;
    margin: 0 auto 30px;
    line-height: 1.6em;
    font-size: 16px;
}
#contact-us div.content div.formContainer { 
    width: 41%;
    margin: 0 auto;
}
#contact-us div.content div.formContainer form { 
    width: 100%;
}
#contact-us div.content div.formContainer form div.subject { 
   margin-bottom: 30px;
}
#contact-us div.content div.formContainer form div.subject select { 
    width: 100%;
    padding: 12px 8px;
    border-radius: 5px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    font-size: 16px;
    background-color: #fff;
    color: rgb(34, 34, 34);
}
#contact-us div.content div.formContainer form div.subject select::-ms-expand  { 
    display: none;
}
#contact-us div.content div.formContainer form div.subject select:focus  { 
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
#contact-us div.content div.formContainer form div.subject select option { 
   
}
#contact-us div.content div.formContainer form div.textArea { 
    width: 100%;
}
#contact-us div.content div.formContainer form div.textArea textarea { 
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    resize: vertical;
}
#contact-us div.content div.formContainer form div.textArea textarea:focus { 
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);

}
#contact-us div.content div.formContainer form div.textAreaDetails { 
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
#contact-us div.content div.formContainer form div.textAreaDetails div.left { 

}
#contact-us div.content div.formContainer form div.textAreaDetails div.left p.imageName { 
        margin-bottom: 10px;
        text-align: left;
}
#contact-us div.content div.formContainer form div.textAreaDetails div.left span { 
    
}
#contact-us div.content div.formContainer form div.textAreaDetails div.right { 

}
#contact-us div.content div.formContainer form div.textAreaDetails div.right label {

}
#contact-us div.content div.formContainer form div.textAreaDetails div.right label div.button {
    background-color: #000;
    padding: 4px 21px;
    border: 2px solid #fff;
    border-radius: 30px;
    color: #fff;
    font-size: 18px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
#contact-us div.content div.formContainer form div.textAreaDetails div.right label div.button:hover {
    background-color: #213a64;
}
#contact-us div.content div.formContainer form div.textAreaDetails div.right label div.button img {
    width: 25px;
}
#contact-us div.content div.formContainer form div.textAreaDetails div.right input { 
    display: none;
    visibility: none;
}
#contact-us div.content div.formContainer form div.captcha { 

}
#contact-us div.content div.formContainer form div.submit { 

}
#contact-us div.content div.formContainer form div.submit button { 
    background-color: #000;
    padding: 8px 21px;
    border: 2px solid #fff;
    border-radius: 30px;
    color: #fff;
    font-size: 18px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    width: 33%;
}
#contact-us div.content div.formContainer form div.submit button:hover { 
    background-color: #213a64;
}


/* Responsive section  */

@media all and (max-width:1280px){
    /* #contact-us div.content {
        background-position-x: -143px;
    } */
    #contact-us div.content p.description {
        max-width: 88%;
    }
    #contact-us div.content div.formContainer {
        width: 82%;
    }
    #contact-us div.content div.formContainer form div.submit button {
        width: 25%;
    }
}
@media all and (max-width:980px){
    /* #contact-us div.content {
        background-position-x: -220px;
    } */
    #contact-us div.content p.description {
        max-width: 73%;
    }
    #contact-us div.content div.formContainer {
        width: 75%;
    }
    #contact-us div.content div.formContainer form div.submit button {
        width: 32%;
    }
}
@media all and (max-width:768px){
    /* #contact-us div.content {
        background-position-x: -292px;
    } */
    #contact-us div.content p.description {
        max-width: 90%;
    }
    #contact-us div.content div.formContainer {
        width: 89%;
    }
}
@media all and (max-width:640px){
    /* #contact-us div.content {
        background-position-x: -409px;
    } */
    #contact-us div.content {
        padding: 155px 0 200px;
    }
    #contact-us div.content p.description {
        max-width: 100%;
    }
    #contact-us div.content div.formContainer {
        width: 100%;
    }
    #contact-us div.content div.formContainer form div.submit button {
        width: 40%;
    }
}
@media all and (max-width:480px){
    /* #contact-us div.content {
        background-position-x: -551px;
    } */
    #contact-us div.content div.formContainer form div.submit button {
        width: 44%;
    }
}
@media all and (max-width:360px){
    
}
@media all and (max-width:320px){
    
}