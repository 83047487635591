#about {
    padding: 150px 0 0;
    color: #fff;;
}
#about h1 {
    text-align: center;
    margin-bottom: 50px;
}
#about div.content {
    background: url("../../assets/images/userspage-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0 300px;
}   
#about div.content p {
    max-width: 50%;
    margin: 0 auto;
    line-height: 1.6em;
    font-size: 16px;
}

@media all and (max-width:1280px){

}
@media all and (max-width:1050px){
    #about div.content p {
        max-width: 70%;
    }
}
@media all and (max-width:768px){
    #about div.content {
        background-position-x: -149px;
    }
    #about div.content p {
        max-width: 91%;
    }
    #about div.content {
        background-position-x: -259px;
    }
}
@media all and (max-width:640px){
    #about div.content {
        background-position-x: -259px;
    }
}
@media all and (max-width:480px){
    #about div.content {
        background-position-x: -355px;
    }
}
