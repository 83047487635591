#sundayLive { 
    padding: 250px 0 200px;
    
}
#sundayLive div.wrapper { 
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#sundayLive div.wrapper div.leftContainer { 
    width: 50%;
}
#sundayLive div.wrapper div.leftContainer div.logoContainer { 
    width: 75%;
}
#sundayLive div.wrapper div.leftContainer div.logoContainer img { 
        display: block;
        width: 100%;
}
#sundayLive div.wrapper div.rightContainer { 
    width: 50%;
    color: #fff;
}
#sundayLive div.wrapper div.rightContainer h1 { 
    font-size: 38px;
    margin-bottom: 20px;
}
#sundayLive div.wrapper div.rightContainer p { 
    font-size: 21px;
}
#sundayLive div.wrapper div.rightContainer div.timer { 
    margin-top: 35px;
    display: flex;
}
#sundayLive div.wrapper div.rightContainer div.timer div.timer-div { 
    margin-right: 20px;
    text-align: center;
}
#sundayLive div.wrapper div.rightContainer div.timer div.timer-div span { 
    display: block;
    font-size: 35px;
    font-weight: 600;
}
#sundayLive div.wrapper div.rightContainer div.timer div.timer-div span.days { 
    font-size: 16px;
    font-weight: 400;
}

/* Responsive section */


@media all and (max-width:1280px){

}
@media all and (max-width:980px){
    #sundayLive div.wrapper {
        flex-direction: column;
    }
    #sundayLive div.wrapper div.leftContainer {
        width: 100%;
        margin-bottom: 30px;
    }
    #sundayLive div.wrapper div.leftContainer div.logoContainer {
        width: 59%;
        margin: 0 auto;
    }
    #sundayLive div.wrapper div.rightContainer {
        width: 100%;
        color: #fff;
    }
    #sundayLive div.wrapper {
        width: 86%;
    }
}
@media all and (max-width:768px){
    
}
@media all and (max-width:640px){
    #sundayLive {
        padding: 300px 0 90px;
    }
}
@media all and (max-width:480px){
    
}
@media all and (max-width:360px){
    
}
@media all and (max-width:320px){
    
}