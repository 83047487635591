
ol {
    counter-reset: item;
    padding-left: 0px !important;
}
#termsAndConditon {
    padding: 150px 0 0;
    color: #fff;;
}
#termsAndConditon h1 {
    text-align: center;
    margin-bottom: 30px;
}
#termsAndConditon div.content {
    background: url("../../assets/images/userspage-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0;
    min-height: 500px;
    background-position-x: 49%;
}
#termsAndConditon div.content div.wrapper {
    width: 75%;
    margin: 0 auto;
}
#termsAndConditon  p.head {
    font-size: 16px;
    margin-bottom: 30px;
}
#termsAndConditon div.content p {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.4em;
}
#termsAndConditon div.content h3 {
    font-size: 20px;
    margin-bottom: 10px;
}
#termsAndConditon div.content p.end {
    margin-bottom: 30px;
}
#termsAndConditon div.content ol li {
    
}
#termsAndConditon div.content ol li.count::before {
    font-size: 20px;
    content: counters(item, ".") " ";
    counter-increment: item;
}
#termsAndConditon div.content ol li.count p.big{
    font-size: 21px;        
}
#termsAndConditon div.content ol li span {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    display: inline-block;
}
#termsAndConditon div.content ol li ol li span {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 4px;
}
#termsAndConditon div.content ol li ol li p {
    font-size: 18px;
}
#termsAndConditon div.content ol li ol li ul {
    padding-left: 20px;
    margin-bottom: 30px;
}
#termsAndConditon div.content ol li ol li ul.no-margin {
    margin-bottom: 0;
}
#termsAndConditon div.content ol li ol li ul li {
    list-style: square;
    font-size: 18px;
    line-height: 1.4em;
}
#termsAndConditon div.content ol li ol li ul li b {
   
}
#termsAndConditon div.content ol li ul.last {
    padding-left: 20px;
}
#termsAndConditon div.content ol li ul.last li {
    list-style-type: circle;
    font-size: 20px;
}


/* Responsive Section  */

@media all and (max-width:1280px){
    #termsAndConditon div.content div.wrapper {
        width: 87%;
    }
    #termsAndConditon div.content {
        background-position-x: 53%;
    }
}
@media all and (max-width:980px){
    #termsAndConditon div.content {
        background-position-x: 52%;
    }
}
@media all and (max-width:768px){
    #termsAndConditon h1 {
        font-size: 29px;
    }
}
@media all and (max-width:640px){
    #termsAndConditon div.content div.wrapper {
        width: 90%;
    }
    #termsAndConditon div.content ol li.count p.big {
        font-size: 18px;
    }
    #termsAndConditon div.content ol li ol li ul li {
        font-size: 16px;
    }
    #termsAndConditon div.content ol li span {
        font-size: 19px;
    }
    #termsAndConditon div.content ol li ul.last li {
        font-size: 18px;
    }
    #termsAndConditon div.content ol li span {
        display: inline;
    }
}
@media all and (max-width:480px){
    
}
@media all and (max-width:360px){
    
}
@media all and (max-width:320px){
    
}