#schedule { 
    padding: 170px 0 30px;
}
#schedule div.scheduleContainer { 
    padding-bottom: 40px;
}
#schedule div.scheduleContainer h1 { 
    color: #fff;
}
#schedule div.scheduleContainer div.weeklyContainer { 
    width: 80%;
    margin: 50px auto 0;
}
#schedule div.scheduleContainer div.weeklyContainer .slick-prev{
    left: -47px !important;
    top: 39% !important;
}
#schedule div.scheduleContainer div.weeklyContainer .slick-next{
    right: -47px !important;
    top: 39% !important;
}
#schedule div.scheduleContainer div.weeklyContainer div.weeks { 

}
#schedule div.scheduleContainer div.weeklyContainer div.weeks button.weekDays { 
    background-color: transparent;
    padding: 8px 30px;
    border: 2px solid #fff;
    border-radius: 30px;
    color: var(--text-color);
    font-size: 16px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    width: 95%;
}
#schedule div.scheduleContainer div.weeklyContainer div.weeks button.weekDays:hover { 
    background-color: #213a64;
}
#schedule div.scheduleContainer div.earlierShowsContainer { 
    margin-top: 40px;
}
#schedule div.scheduleContainer div.earlierShowsContainer button { 
    background-color: transparent;
    padding: 8px 30px;
    border: 2px solid #fff;
    border-radius: 30px;
    color: var(--text-color);
    font-size: 18px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    display: flex;
    align-items: center;
}
#schedule div.scheduleContainer div.earlierShowsContainer button:hover { 
    background-color: #213a64
}
#schedule div.scheduleContainer div.earlierShowsContainer button img { 
    width: 18px;
    margin-left: 10px;
}

#schedule #allShows { 
    /* background: url("../../assets/images/comingup-gradient-live.png") repeat-x top center;
    background-size: cover; */
    background:transparent linear-gradient(180deg, #001332 0%, #00191500 10%) 0% 0% no-repeat padding-box;
    
    color: #fff;
    padding: 15px 0 30px;
}
#schedule #allShows div.showsContainer { 

}
#schedule #allShows div.showsContainer div.shows { 
    margin-bottom: 30px;
}
#schedule #allShows div.showsContainer div.shows:last-child { 
    margin-bottom: 0;
}
#schedule #allShows div.showsContainer div.shows h3 { 
    font-size: 25px;
}
#schedule #allShows div.showsContainer div.shows div.showContainer { 
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#schedule #allShows div.showsContainer div.shows div.showContainer div.leftContainer { 
    width: 28%;
}
#schedule #allShows div.showsContainer div.shows div.showContainer div.leftContainer div.imageContainer { 
    width: 100%;
}
#schedule #allShows div.showsContainer div.shows div.showContainer div.leftContainer div.imageContainer img { 
    width: 100%;
    display: block;
    border-radius: 10px;
}
#schedule #allShows div.showsContainer div.shows div.showContainer div.rightContainer { 
    width: 70%;
}
#schedule #allShows div.showsContainer div.shows div.showContainer div.rightContainer h1.title { 
    margin-bottom: 10px;
}
#schedule #allShows div.showsContainer div.shows div.showContainer div.rightContainer p.description { 
    margin-bottom: 20px;
    max-width: 60%;
}
#schedule #allShows div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer { 
    display: flex;
}
#schedule #allShows div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button { 
    background-color: var(--button-color);
    padding: 8px 40px;
    border: 2px solid #fff;
    border-radius: 30px;
    color: var(--white-color);
    font-size: 18px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    margin-right: 20px;
}
#schedule #allShows div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button:hover { 
    background-color: #213a64;
}
#schedule #allShows div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button:last-child { 
    display: flex;
    align-items: center;
    margin-right: 0;
}
#schedule #allShows div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button:last-child img { 
    width: 18px;
    margin-left: 6px;
}
.activeButtonBgChange{
    background: var(--active-color) !important;
    color: var(--white-color) !important;
}

#schedule #allShows div.bottom {
    background: url("../../assets//images//shedule-gradient.png") repeat-x center bottom;
    background-size: contain;
    position: absolute;
    left: 0;
    bottom: 26px;
    width: 100%;
    height: 113px;
}
#schedule #allShows div.bottom div.downArrowContainer {
    width: 55px;
    margin: 63px auto 0;
    cursor: pointer;
}
#schedule #allShows div.bottom div.downArrowContainer img {
    display: block;
    width: 100%;
}

/* responsive-section */

@media all and (max-width:1280px){
    #schedule #allShows div.showsContainer div.shows div.showContainer div.rightContainer p.description {
        max-width: 90%;
        font-size: 15px;
    }
    #schedule #allShows div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button {
        font-size: 15px;
    }
    #schedule #allShows div.showsContainer div.shows h3 {
        font-size: 18px;
    }
}
@media all and (max-width:980px){
    #schedule #allShows div.showsContainer div.shows div.showContainer div.leftContainer {
        width: 44%;
    }
    #schedule #allShows div.showsContainer div.shows div.showContainer div.rightContainer {
        width: 54%;
    }
    #schedule #allShows div.showsContainer div.shows div.showContainer div.rightContainer p.description {
        max-width: 100%;
    } 
    #schedule #allShows div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button {
        padding: 8px 21px;
    }
    #schedule #allShows div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button.cancelReminder {
        padding: 8px 10px;
    }
}
@media all and (max-width:768px){
    #schedule #allShows div.showsContainer div.shows div.showContainer {
        flex-direction: column;
    }
    #schedule #allShows div.showsContainer div.shows div.showContainer div.leftContainer {
        width: 100%;
    }
    #schedule #allShows div.showsContainer div.shows div.showContainer div.rightContainer {
        width: 100%;
        margin-top: 30px;
    }
    .wrapper {
        width: 96%;
        margin: 0 auto;
    }
}
@media all and (max-width:640px){
    
}
@media all and (max-width:480px){
    #schedule #allShows div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer {
        flex-direction: column;
    }
    #schedule #allShows div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button {
        margin-right: 0;
        margin-bottom: 10px;
        width: 67%;
    }
    #schedule #allShows div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button:last-child {
        margin-bottom: 0;
    }
    #schedule #allShows div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button:last-child{
        justify-content: center;
    }
}
@media all and (max-width:360px){
    
}
@media all and (max-width:320px){
    
}
