#onDemand .slick-dots {
    position: absolute;
    bottom: 35px !important;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  #onDemand{
    overflow-x: hidden;
  }
  #onDemand .slick-dots li.slick-active button:before {
    opacity: .75 !important;
    color: #fff !important;
  }
  #onDemand .slick-dots li button:before {
    font-size: 14px !important;
  }
  #onDemand .slick-dots li button:before {
    opacity: 0.25 !important;
    color: #fff !important;
  }
#onDemand .slick-prev {
    left: -10px !important;
    z-index: 9 !important;
}
#onDemand .slick-next{
    z-index: 9 !important;
    right: -10px !important;
}

#onDemand {
    padding: 100px 0;
}
#onDemand #features {
    margin-top: 50px;
}
#onDemand #features .items {
  position: relative;
  padding: 67px 50px;
  overflow: hidden;
}
#onDemand #features div.items div.background {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  filter: blur(4px);
  overflow: hidden;
}
#onDemand #features div.items div.background img {
  display: block;
  width: 100%;
}
#onDemand #features div.items div.containers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#onDemand #features div.items div.containers div.leftContainer {
  width: 45%;
}
#onDemand #features div.items
  div.containers
  div.leftContainer
  div.imageContainer {
  display: block;
  max-width: 100%;
  height: auto;
}
#onDemand #features div.items
  div.containers
  div.leftContainer
  div.imageContainer
  img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  border-radius: 10px;
}
#onDemand #features div.items div.containers div.rightContainer {
  width: 50%;
}
#onDemand #features div.items
  div.containers
  div.rightContainer
  h1.title {
  color: #fbfbfb;
  font-size: 28px;
  margin-bottom: 18px;
}
#onDemand #features div.items div.containers div.rightContainer p {
  color: var(--white-color) !important;
}
#onDemand #features div.items
  div.containers
  div.rightContainer
  div.buttonContainer {
  margin-top: 30px;
  display: flex;
}
#onDemand #features div.items
  div.containers
  div.rightContainer
  div.buttonContainer
  button {
  margin-right: 30px;
  background-color: var(--button-color);
  padding: 8px 26px 8px 43px;
  border: 2px solid #fff;
  border-radius: 30px;
  color: #e3e3e3;
  font-size: 16px;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 10rem;
  justify-content: right;
}
#onDemand #features div.items
  div.containers
  div.rightContainer
  div.buttonContainer
  button
  div.list {
  display: flex;
  align-items: center;
}
#onDemand #features div.items
  div.containers
  div.rightContainer
  div.buttonContainer
  button
  img {
  margin-left: 10px;
  width: 12px;
}
#onDemand #features div.items
  div.containers
  div.rightContainer
  div.buttonContainer
  button:first-child
  img {
  margin-left: 30px;
  width: 12px;
}
#onDemand #features div.items
  div.containers
  div.rightContainer
  div.buttonContainer
  button:last-child {
  margin-right: 0;
  position: relative;
}
#onDemand #features div.items
  div.containers
  div.rightContainer
  div.buttonContainer
  button:hover{
    background-color: #3b66af;
  }
/* #onDemand #features div.items
  div.containers
  div.rightContainer
  div.buttonContainer
  button:last-child
  img {
  margin-left: 20px;
  width: 12px;
} */
/* #onDemand #features .items div.leftContainer {
    width: 48%;
}
#onDemand #features .items div.leftContainer div.imageContainer {
    width: 88%;
}
#onDemand #features .items div.leftContainer div.imageContainer img {
    width: 100%;
    display: block;
    z-index: 9;
    border-radius: 10px;  
}
#onDemand #features .items div.rightContainer {
    width: 52%;
}
#onDemand #features .items div.rightContainer h1.heading {
    color: #fff;
    font-size: 25px;
    margin-bottom: 20px;
}
#onDemand #features .items div.rightContainer p {
    line-height: 1.5em;
    color: #fff;
}
#onDemand #features .items div.rightContainer div.buttonContainer {
    margin-top: 30px;
    display: flex;
}
#onDemand #features .items div.rightContainer div.buttonContainer button {
    margin-right: 30px;
    background-color: #000;
  padding: 8px 50px;
  border: 2px solid #fff;
  border-radius: 30px;
  color: #fff;
  font-size: 18px;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
}
#onDemand #features .items div.rightContainer div.buttonContainer button:hover{
    background-color: #213A64;
}
#onDemand #features .items div.rightContainer div.buttonContainer button:last-child {
    margin-right: 0;
    padding: 8px 25px;
}
#onDemand #features .items div.rightContainer div.buttonContainer button img {
    margin-left: 20px;
    width: 12px;
} */

#onDemand div.whatsNewSection  {
    margin-top: 30px;
}
#onDemand div.whatsNewSection div.shows  {
    margin-bottom: 30px;
}
#onDemand div.whatsNewSection div.shows:last-child  {
    margin-bottom: 0;
}

#onDemand div.whatsNewSection div.shows div.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  /* padding: 0 20px; */
}
#onDemand div.whatsNewSection div.shows div.top h1.heading {
  color: #fff;
  font-size: 32px;
  width: 85%;
  margin: 0 7%;
}
#onDemand div.whatsNewSection div.shows div.top div.buttonContainer {
margin-right: 10px;
}
#onDemand div.whatsNewSection div.shows div.top div.buttonContainer button {
    background-color: #000;
    padding: 6px 10px;
    border: 2px solid #fff;
    border-radius: 30px;
    color: #fff;
    font-size: 16px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    display: flex;
    align-items: center;
}
#onDemand div.whatsNewSection div.shows div.top div.buttonContainer button:hover {
    background-color: #213A64;
}
#onDemand div.whatsNewSection div.shows div.top div.buttonContainer button img {
    width: 12px;
    margin-right: 6px;
}
#onDemand div.whatsNewSection div.shows div.slickSection {
  max-width: 98%;
  margin: 10px auto 0;
}
#onDemand div.whatsNewSection div.shows div.slickSection .slick-list {
  overflow: visible !important;

}
#onDemand div.whatsNewSection div.shows div.slickSection .slick-prev{
  left: -66px !important;
  width: 11% !important;
  height: 100% !important;
  background-image: url(../../assets/images/carousel\ gradient\ -\ l.svg) !important;
  background-repeat: no-repeat;
  background-position: center;
}
#onDemand div.whatsNewSection div.shows div.slickSection .slick-next{
  right: -66px !important;
  width: 11% !important;
  height: 100% !important;
  background-image: url(../../assets/images/carousel\ gradient\ -\ r.svg) !important;
  background-repeat: no-repeat;
  background-position: center;
}
#onDemand div.whatsNewSection div.shows div.slickSection ul {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
#onDemand div.whatsNewSection div.shows div.slickSection div.episodes {
  margin-right: 20px;
  transition: all 0.5s ease 0s;
  max-height: 250px;
  padding: 20px 0;
}
#onDemand div.whatsNewSection div.shows div.slickSection div.episodes:hover {
  transform: scale(1.1);
  opacity: 1;
  cursor: pointer;
}
#onDemand div.whatsNewSection div.shows div.slickSection div.episodes div.descriptionContainer{
  background: url(../../assets/images/bg-header.png);
  width: 228px;
  padding: 8px 10px;
  box-sizing: border-box;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  left: 10px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s ease, opacity 0.3s ease;
  z-index: 1;
  top: 85%;
}
#onDemand div.whatsNewSection div.shows div.slickSection div.episodes:hover div.descriptionContainer{
  visibility: visible; 
  opacity: 1; 
  margin-left: 0;
}
#onDemand div.whatsNewSection div.shows div.slickSection div.episodes:hover div.descriptionContainer p {
  font-size: 15px;
  color: var(--text-color);
  margin: 0;
  display: -webkit-box; 
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; 
  text-overflow: ellipsis;
}
#onDemand div.whatsNewSection div.shows div.slickSection div.episodes:hover div.descriptionContainer span p {
  font-size: 13px;
  color: var(--text-color);
  margin: 0;
  display: -webkit-box; 
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; 
  text-overflow: ellipsis;
}

#onDemand div.whatsNewSection div.shows div.slickSection div.episodes div.imageContainer {
  width: 228px;
  transition: 0.3s ease-in-out;
  margin: 0 auto;
}
#onDemand div.whatsNewSection div.shows div.slickSection div.episodes p {
  color: #fff;
  font-size: 16px;
  margin: 6px auto 0;
  max-width: 228px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#onDemand div.whatsNewSection div.shows div.slickSection div.episodes:hover >  p {
  display: none;
}
#onDemand div.whatsNewSection div.shows div.slickSection div.episodes div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 10px;
}

/* Responsive section */
@media all and (max-width:2600px){
  #onDemand div.whatsNewSection div.shows div.slickSection div.episodes div.descriptionContainer {
    margin-left: 7px;
  }
}
@media all and (max-width:1980px){
  #onDemand #features div.items div.containers div.rightContainer div.buttonContainer button {
    width: 11rem;
  }
}
@media all and (max-width:1600px){
  #onDemand #features div.items div.containers div.rightContainer div.buttonContainer button {
    padding: 8px 26px 8px 26px;
  }
}
@media all and (max-width:1500px){
  #onDemand div.whatsNewSection div.shows div.slickSection div.episodes div.imageContainer {
    width: 211px;
  }
  #onDemand div.whatsNewSection div.shows div.slickSection div.episodes div.descriptionContainer {
    width: 211px;
  }
  #onDemand div.whatsNewSection div.shows div.slickSection div.episodes p{
    max-width: 211px;
  }
}
@media all and (max-width:1400px){
  #onDemand #features div.items div.containers div.rightContainer h1.title {
    
    margin-bottom: 26px;
}
#onDemand div.whatsNewSection div.shows div.slickSection div.episodes div.descriptionContainer {
  
  margin-left: 8%;
}
#onDemand #features div.items div.containers div.rightContainer div.buttonContainer {
  margin-top: 16px;
}

#onDemand .slick-dots {
  bottom: 50px !important;
}
}
@media all and (max-width:1200px){
    #onDemand div.whatsNewSection div.shows div.slickSection ul li div.imageContainer {
        width: 220px;
    }
    #onDemand div.whatsNewSection div.shows div.slickSection div.episodes div.imageContainer {
        width: 219px;
    }
    #onDemand div.whatsNewSection div.shows div.slickSection div.episodes div.descriptionContainer {
      width: 219px;
      margin-left: -1%;
    }
    #onDemand div.whatsNewSection div.shows div.slickSection div.episodes p{
      max-width: 219px;
    }
    #onDemand div.whatsNewSection div.shows div.slickSection .slick-prev {
      left: -25px !important;
    }
    #onDemand div.whatsNewSection div.shows div.slickSection .slick-next {
      right: -25px !important;
    }
    #onDemand #features div.items {
      padding: 136px 50px;
    }
    #onDemand #features div.items
      div.containers
      div.rightContainer
      div.buttonContainer
      button {
      width: 11rem;
    }
    #onDemand #features div.items div.containers div.leftContainer {
      width: 43%;
    }
    #onDemand #features div.items div.containers div.rightContainer {
      width: 54%;
    }
    #onDemand #features div.items
      div.containers
      div.rightContainer
      h1.title {
      font-size: 26px;
      margin-bottom: -1px;
    }
    #onDemand #features div.items
      div.containers
      div.rightContainer
      div.buttonContainer {
      margin-top: 11px;
    }
    #onDemand #features div.items div.containers div.rightContainer p {
      color: #fbfbfb;
      font-size: 13px;
    }
    #onDemand #features div.items
      div.containers
      div.rightContainer
      div.buttonContainer
      button {
      padding: 6px 26px 6px 43px;
    }
    #onDemand .slick-dots {
      bottom: 56px !important;
  }
}
@media all and (max-width:1024px){
    #onDemand div.whatsNewSection div.shows div.shows div.slickSection div.episodes div.imageContainer {
        width: 223px;
        transition: 0.3s ease-in-out;
    }
}
@media all and (max-width:980px){
    #onDemand #features .items div.rightContainer div.buttonContainer {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
    }
    #onDemand div.whatsNewSection div.shows div.slickSection div.episodes div.descriptionContainer {
      margin-left: 13% !important;
    }
    #onDemand #features div.items div.containers {
      flex-direction: column;
  }
    #onDemand #features .items div.rightContainer div.buttonContainer button.removeList{
      width: 70%;
    }
    #onDemand #features div.items div.background img {
      height: 100%;
      object-fit: cover;
  }
  #onDemand #features div.items div.containers div.leftContainer {
    width: 100%;
    margin-bottom: 20px;
}
#onDemand #features div.items div.containers div.rightContainer {
  width: 100%;
}
#onDemand #features div.items div.containers div.rightContainer div.buttonContainer {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}
#onDemand #features div.items div.containers div.rightContainer div.buttonContainer button {
  width: 10rem;
}
#onDemand #features div.items div.containers div.rightContainer p {
  font-size: 14px;
}
#onDemand #features div.items div.containers div.rightContainer div.buttonContainer button {
  padding: 6px 26px 6px 21px;
}

}
@media all and (max-width:768px){
    #onDemand #features .items {
        flex-direction: column;
        padding: 45px 50px 165px;
      }
      #onDemand #features .items div.leftContainer {
        width: 100%;
        margin-bottom: 23px;
    }
    #onDemand #features .items div.rightContainer {
      width: 100%;
    }
    #onDemand #features .items div.rightContainer div.buttonContainer {
      flex-direction: row;
    }
    #onDemand #features .items div.rightContainer div.buttonContainer button {
      margin-right: 10px;
      margin-bottom: 0;
    }
    #onDemand #features .items div.rightContainer div.buttonContainer button:last-child {
      margin-right: 0;
    }
    #onDemand #ck-dots {
      bottom: 16px !important;
    }
    #onDemand div.whatsNewSection div.shows div.slickSection div.episodes div.imageContainer {
        width: 286px;
    }
    #onDemand div.whatsNewSection div.shows div.slickSection div.episodes div.descriptionContainer {
      width: 286px;
      margin-left: 35px !important;
    }
    #onDemand div.whatsNewSection div.shows div.slickSection div.episodes p{
      max-width: 286px;
    }
    #onDemand #features .items div.rightContainer div.buttonContainer button.removeList{
      width: 45%;
    }
}
@media all and (max-width:640px){
  #onDemand div.whatsNewSection div.shows div.slickSection .slick-prev {
    left: 2px !important;
    background-size: cover !important;
}
#onDemand div.whatsNewSection div.shows div.slickSection .slick-next {
  right: 2px !important;
  background-size: cover !important;
}
    #onDemand #features .items div.rightContainer div.buttonContainer button {
        padding: 9px 27px 9px 62px;
        font-size: 19px;
        justify-content: space-between;
    }
      #onDemand #features .items div.rightContainer div.buttonContainer button img {
          margin-left: 10px;
      }
      #onDemand #features .items {
          padding: 98px 29px;
      }
      #onDemand div.whatsNewSection div.shows div.slickSection ul li div.imageContainer {
        width: 237px;
    }
    #onDemand div.whatsNewSection div.shows div.slickSection div.episodes:hover >  p {
      display:block;
    }
    #onDemand div.whatsNewSection h1.heading {
        font-size: 21px;
    }
    #onDemand div.whatsNewSection div.shows div.slickSection div.episodes div.imageContainer {
        width: 90%;
        transition: 0.3s ease-in-out;
        margin: 0 auto;
    }
    #onDemand div.whatsNewSection div.shows div.slickSection div.episodes div.descriptionContainer {
      display: none;
    }
    #onDemand div.whatsNewSection div.shows div.slickSection div.episodes p {
      max-width: 90%;
  }
    #onDemand .slick-prev {
        left: -3px !important;
    }
    
    #onDemand div.whatsNewSection div.shows div.top h1.heading {
        font-size: 29px;
    }
    #onDemand div.whatsNewSection div.shows div.top div.buttonContainer button {
        font-size: 14px;
    }
    #onDemand #features .items div.rightContainer div.buttonContainer {
      flex-direction: column;
  }
  #onDemand #features .items div.rightContainer div.buttonContainer button.removeList {
    width: 58%;
}
#onDemand #features div.items {
  padding: 72px 27px 144px;
}
#onDemand #features div.items div.containers div.rightContainer div.buttonContainer button {
  width: 10rem;
}
}
@media all and (max-width:480px){
    #onDemand #features .items div.rightContainer div.buttonContainer {
        flex-direction: column;
    }
    #onDemand #features .items div.rightContainer div.buttonContainer button {
        margin-right: 0;
        width: 166px;
        padding: 9px 27px 9px 56px;
        justify-content: space-between;
    }
    #onDemand #features .items div.rightContainer div.buttonContainer button:last-child {
        margin-top: 10px;
    }
    #onDemand div.whatsNewSection div.shows div.top h1.heading {
        font-size: 26px;
    }
    #onDemand #features .items div.rightContainer div.buttonContainer button.removeList {
      width: 80%;
  }
  #onDemand #features div.items div.containers div.rightContainer div.buttonContainer {
    flex-direction: column;
}
#onDemand #features div.items div.containers div.rightContainer div.buttonContainer button {
  width: 10rem;
  margin-right: 0;
}
}
@media all and (max-width:360px){
    #onDemand div.whatsNewSection div.shows div.top h1.heading {
        font-size: 24px;
    }
    #onDemand #features .items div.rightContainer div.buttonContainer button.removeList {
      width: 93%;
  }
  #onDemand #features div.items div.containers div.rightContainer div.buttonContainer button {
    margin-right: 0;
}
}
