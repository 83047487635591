footer { 
    display: flex;
    padding: 60px 0;
    justify-content: space-between;
}
footer div.wrapper{
    display: flex;
    justify-content: space-between;
}
footer div.leftSection { 
    display: flex;
    width: 57%;
}
footer div.leftSection div.left { 
    margin-right: 58px;

}
footer div.leftSection div.left div.imageContainer { 
    width: 190px;
}
footer div.leftSection div.left div.imageContainer img { 
    display: block;
    width: 100%;
}
footer div.leftSection div.right { 


}
footer div.leftSection div.right ul.social { 
    display: flex;
    margin-bottom: 30px;
}
footer div.leftSection div.right ul.social li { 
    margin-right: 10px;
}
footer div.leftSection div.right ul.social li:last-child { 
    margin-right: 0;

}
footer div.leftSection div.right ul.social li div.iconIcontainer { 

}
footer div.leftSection div.right ul.social li div.iconIcontainer img { 

}
footer div.leftSection div.right ul.links { 
    display: flex;
}
footer div.leftSection div.right ul.links a { 
    display: flex;
    margin-right: 20px;
}
footer div.leftSection div.right ul.links li { 
    color: var(--text-color);
    font-size: 16px;

}
footer div.leftSection div.right ul.links li:last-child { 
    margin-right: 0;

}
footer div.rightSection { 
    text-align: right;
    color: #fff;
    width: 46%;
}
footer div.rightSection h2 { 
    font-size: 24px;
    text-align: right;
}
footer div.rightSection div.storeContainer { 
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 60%;
    margin: 20px 0 0 auto;
}
footer div.rightSection div.logoContainer { 
   width: 28%;
   margin-bottom: 10px;
   margin-right: 10px;
   cursor: pointer;
   &:last-child{
    margin-right: 0;
   }

}
footer div.rightSection div.logoContainer:nth-child(3n) { 
  margin-right: 0;
 
 }
footer div.rightSection div.logoContainer img { 
   width: 100%;
   display: block;
   border-radius: 5px;

}
footer div.rightSection  div.googletv { 
   
        width: 33%;
      
        height: 32px;
    
 }
 footer div.rightSection  div.googletv img{ 
    width: 87%;
    height: 30px;
    margin-left: 16px;
    display: block;
    border-radius: 5px;
 
 }
footer div.rightSection div.storeContainer div.googlePlaystoreContainer { 
    margin-right: 20px;
    width: 160px;
}
footer div.rightSection div.storeContainer div.googlePlaystoreContainer img { 
    width: 100%;
    display: block;
}
footer div.rightSection div.storeContainer div.appleStoreContainer { 
    width: 160px;
}
footer div.rightSection div.storeContainer div.appleStoreContainer img { 
    width: 100%;
    display: block;
}
footer div.rightSection div.copyrightContainer { 
    margin-top: 30px;
    font-size: 16px;
}
footer div.rightSection div.copyrightContainer p { 
    font-size: 14px;
    line-height: 1.6em;
}
footer div.rightSection div.copyrightContainer p br { 

}
footer div.rightSection div.copyrightContainer p a { 
    display: inline-block;
    color: var(--active-color);
}
@media all and (max-width:2600px){
        footer div.rightSection  div.googletv img{ 
            height: 52px;
            margin-left: 27px;
            width: 85%;
        }
}
@media all and (max-width:2500px){
    footer div.rightSection  div.googletv img{ 
        height: 48px;
        margin-left: 26px;
        width: 85%;
    }
}
@media all and (max-width:2200px){
    footer div.rightSection  div.googletv img{ 
        height: 44px;
        margin-left: 23px;
    }
}
@media all and (max-width:1980px){
    footer div.rightSection  div.googletv img{ 
        height: 39px;
        margin-left: 21px;    
    }
}
@media all and (max-width:1768px){
    footer div.rightSection  div.googletv img{ 
        height: 36px;
        margin-left: 21px;    
    }
}
@media all and (max-width:1600px){
    footer div.rightSection  div.googletv img{ 
        height: 31px;
        margin-left: 19px;    
    }
}
@media all and (max-width:1400px){
    footer div.leftSection div.left {
        margin-right: 37px;
    }
    footer div.leftSection div.right ul.links a {
        margin-right: 12px;
    }
    footer div.rightSection  div.googletv img{ 
        height: 27px;
        margin-left: 17px;  
    }
}
@media all and (max-width:1280px){
    footer div.rightSection  div.googletv img{ 
        height: 27px;
        margin-left: 1px;
        width: 100%; 
    }
    footer div.rightSection div.storeContainer div.appleStoreContainer {
        width: 103px
    }
    footer div.rightSection div.storeContainer div.googlePlaystoreContainer {
        width: 103px;
    }
    footer div.leftSection div.right ul.links li {
        font-size: 14px;
    }
    footer div.leftSection div.left div.imageContainer {
        width: 143px;
    }
    footer div.leftSection div.right ul.social li {
        width: 36px;
    }
    footer div.leftSection div.right ul.social li div.iconIcontainer {
        width: 100%;
        display: block;
    }
    footer div.leftSection div.right ul.social li div.iconIcontainer img {
        width: 100%;
        display: block;
    }
    footer .wrapper{
        width: 90%;
    }
    footer div.rightSection div.storeContainer {
        width: 81%;
    }
    footer div.rightSection div.logoContainer {
        width: 29%;
    }
    footer div.leftSection {
        width: 91%;
    }
}
@media all and (max-width:980px){
    footer .wrapper{
        flex-direction: column;
    }
    footer div.rightSection  div.googletv img{ 
        height: 29px;
    }
    footer div.leftSection {
        margin-bottom: 30px;
    }
    footer div.rightSection {
        text-align: left;
    }
    footer div.rightSection div.storeContainer {
        justify-content: flex-start;
        margin: 20px auto 0 0;
    }
    footer div.rightSection h2 {
        font-size: 24px;
        text-align: left;
    }
    
}
@media all and (max-width:768px){
    footer div.leftSection div.right ul.links {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    footer div.leftSection {
        margin-bottom: 30px;
    }
    footer div.leftSection div.right ul.social {
        justify-content: center;
    }
    footer div.leftSection div.right ul.links li:last-child {
        margin-top: 10px;
    }
    footer div.leftSection div.right ul.links li {
        font-size: 16px;
    }
    footer div.rightSection div.storeContainer {
        width: 46%;
    }
    footer div.rightSection {
        width: 100%;
    }
}
@media all and (max-width:640px){
    footer div.rightSection  div.googletv img{ 
        height: 43px;
    }
    footer div.leftSection {
        flex-direction: column;
    }
    footer div.leftSection div.left {
        margin-right: 0;
    }
    footer div.leftSection div.left div.imageContainer {
        margin: 0 auto 30px;
    }
    footer div.rightSection div.storeContainer div.googlePlaystoreContainer {
        width: 130px;
    }
    footer div.rightSection div.storeContainer div.appleStoreContainer {
        width: 130px;
    }
    footer div.rightSection h2 {
        font-size: 23px;
    }
    footer div.leftSection div.right ul.links li {
        font-size: 17px;
    }
    footer div.rightSection div.storeContainer{
        justify-content: flex-start;
        margin: 20px auto 0 0;
        width: 80%;
       
    }
    footer div.rightSection div.logoContainer {
        width: 30%;
    }
}
@media all and (max-width:480px){
    footer div.rightSection  div.googletv img{ 
        height: 33px;
    }  
}
@media all and (max-width:380px){
    footer div.rightSection  div.googletv img{ 
        height: 26px;
    } 
}
@media all and (max-width:360px){
    footer div.rightSection  div.googletv img{ 
        height: 25px;
    } 
}
@media all and (max-width:321px){
    footer div.rightSection  div.googletv img{ 
        height: 22px;
    } 
}