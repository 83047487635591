#changePassword { 
    padding: 150px 0 0;
    color: #fff;
}
#changePassword h1.heading { 
    text-align: center;
}
#changePassword div.passwordContainer { 
    background: url("../../../assets/images/userspage-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0 111px;
    margin-top: 30px;
}
#changePassword div.passwordContainer div.wrapper { 
    display: flex;
    justify-content: left;
    width: 67%;
}
#changePassword div.passwordContainer div.wrapper div.leftContainer { 
    width: 27%;
    margin-right: 60px;
}
#changePassword div.passwordContainer div.wrapper div.leftContainer div.imageContainer { 
    width: 100%;
}
#changePassword div.passwordContainer div.wrapper div.leftContainer div.imageContainer img { 
    display: block;
    width: 100%;
}
#changePassword div.passwordContainer div.wrapper div.rightContainer { 
    width: 35%;
}
#changePassword div.passwordContainer div.wrapper div.rightContainer form { 
    margin-top: 30px;
    width: 100%;
}
#changePassword div.passwordContainer div.wrapper div.rightContainer form div.form-group { 
    margin-bottom: 30px;
}
#changePassword div.passwordContainer div.wrapper div.rightContainer form div.form-group div.oneByOne { 

}
#changePassword div.passwordContainer div.wrapper div.rightContainer form div.form-group div.oneByOne label { 
    display: block;
    margin-bottom: 10px;
}
#changePassword div.passwordContainer div.wrapper div.rightContainer form div.form-group div.oneByOne input { 
    width: 100%;
    padding: 12px 8px;
    border-radius: 5px;
    border: none;
    outline: none;
}
#changePassword div.passwordContainer div.wrapper div.rightContainer form div.form-group div.oneByOne input:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);

}
#changePassword div.passwordContainer div.wrapper div.rightContainer form div.form-group:last-child div.oneByOne{
    text-align: center;
}
#changePassword div.passwordContainer div.wrapper div.rightContainer form div.form-group div.oneByOne button {
    background-color: #000;
    padding: 8px 41px;
    border: 2px solid #fff;
    border-radius: 30px;
    color: #fff;
    font-size: 18px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    width: 73%;
    margin: 0 auto;
}
#changePassword div.passwordContainer div.wrapper div.rightContainer p { 
    font-size: 16px;
    margin-bottom: 7px;
}
#changePassword div.passwordContainer div.wrapper div.rightContainer p span { 
    font-weight: 600;
}
#changePassword div.passwordContainer div.wrapper div.rightContainer p.forgettenPassword { 
    font-weight: 600;
}


/* Responsive section  */


@media all and (max-width:1280px){
    #changePassword div.passwordContainer div.wrapper div.rightContainer {
        width: 42%;
    }
    #changePassword div.passwordContainer div.wrapper div.rightContainer form div.form-group div.oneByOne button {
        width: 85%;
    }
}
@media all and (max-width:980px){
    #changePassword div.passwordContainer {
        background-position-x: -337px;
    }
    #changePassword div.passwordContainer div.wrapper {
        flex-direction: column;
        justify-content: center;
    }
    #changePassword div.passwordContainer div.wrapper div.leftContainer {
        width: 48%;
        margin: 0 auto;
        margin-bottom: 100px;
    }
    #changePassword div.passwordContainer div.wrapper div.rightContainer {
        width: 86%;
        margin: 0 auto;
    }
}
@media all and (max-width:768px){
    #changePassword div.passwordContainer div.wrapper div.leftContainer {
        margin-bottom: 46px;
    }
    #changePassword div.passwordContainer div.wrapper div.rightContainer {
        width: 100%;
    }
}
@media all and (max-width:640px){
    #changePassword div.passwordContainer div.wrapper div.leftContainer {
        width: 60%;
    }
    #changePassword div.passwordContainer div.wrapper {
        width: 93%;
    }
    #changePassword div.passwordContainer {
        background-position-x: -414px;
    }
    #changePassword h1.heading {
        font-size: 31px;
    }
}
@media all and (max-width:480px){
    #changePassword div.passwordContainer div.wrapper div.leftContainer {
        width: 76%;
    }
    #changePassword div.passwordContainer {
        background-position-x: -503px;
    }
}
@media all and (max-width:360px){
    #changePassword div.passwordContainer div.wrapper div.leftContainer {
        width: 86%;
    }
    #changePassword h1.heading {
        font-size: 26px;
    }
}
@media all and (max-width:320px){
    
}