#accountSettings { 
    padding: 170px 0 0;
    color: #fff;
}
#accountSettings h1.heading { 
    text-align: center;
}
#accountSettings div.accountSettingsContainer { 
    background: url("../../../assets/images/userspage-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 25px 0 291px;
    margin-top: 30px;
}
#accountSettings div.accountSettingsContainer div.wrapper { 
    display: flex;
    justify-content: center;
}
#accountSettings div.accountSettingsContainer div.wrapper div.leftContainer { 
    width: 18%;
    margin-right: 60px;
    text-align: center;
}
#accountSettings div.accountSettingsContainer div.wrapper div.leftContainer span { 
    font-size: 20px;
    font-weight: 600;
}
#accountSettings div.accountSettingsContainer div.wrapper div.leftContainer div.imageContainer { 
    /* width: 100%; */
    /* margin-bottom: 30px; */
    margin: 0 auto 20px;
    width: 200px;
}
#accountSettings div.accountSettingsContainer div.wrapper div.leftContainer div.imageContainer img { 
    display: block;
    /* width: 100%; */
    border-radius: 50%;
    object-fit: cover;
    height: 200px;
    margin: 0 auto 20px;
    width: 200px;
}
#accountSettings div.accountSettingsContainer div.wrapper div.rightContainer { 
    width: 55%;
}
#accountSettings div.accountSettingsContainer div.wrapper div.rightContainer h1 { 
    text-align: center;
}
#accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form { 
    margin-top: 30px;
    width: 100%;
}
#accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group { 
    display: flex;
    justify-content: space-between;
    width: 100%;
}
#accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group div.oneByOne { 
    width: 48%;
    margin-bottom: 20px;
}
#accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group div.oneByOne label.changePassword{
    font-weight: 600;
    font-size: 16px;
}
#accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group label { 
    display: block;
    margin-bottom: 10px;
}
#accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group div.oneByOne input { 
    width: 100%;
    padding: 12px 8px;
    border-radius: 5px;
    border: none;
    outline: none;
}
#accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group div.oneByOne input:focus { 
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);

}
#accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group div.oneByOne select:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);

}
#accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group div.oneByOne select {
    width: 100%;
    padding: 12px 8px;
    border-radius: 5px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    font-size: 16px;
    color: rgb(34, 34, 34);
}
#accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group div.oneByOne select::-ms-expand  {
    display: none;
    
}
#accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group div.oneByOne button {
    background-color: var(--button-color);
    padding: 9px 25px;
    border: 2px solid #fff;
    border-radius: 30px;
    color: #fff;
    font-size: 18px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    width: 91%;
}
#accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group div.oneByOne button:hover {
    background-color: #213a64;
}
#accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group:last-child {
    justify-content: space-between;
    margin-top: 80px;
}
#accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group:last-child div.oneByOne {
    text-align: center;
    width: 43%;
}
#accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group:last-child div.oneByOne:last-child {
    margin-right: 0;
}


/* Responsive section */
@media all and (max-width:1500px){
    #accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group div.oneByOne button {
        
        width: 100%;
    }
   
}
@media all and (max-width:1280px){
    
    #accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group:last-child div.oneByOne {
        width: 46%;
    }
    #accountSettings div.accountSettingsContainer div.wrapper div.leftContainer {
        width: 23%;
        margin-right: 98px;
        text-align: center;
    }
    #accountSettings div.accountSettingsContainer div.wrapper div.rightContainer {
        width: 59%;
    }
}
@media all and (max-width:980px){
    /* #accountSettings div.accountSettingsContainer {
        background-position-x: -331px;
    } */
    #accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group:last-child {
        justify-content: center;
    }
    #accountSettings div.accountSettingsContainer div.wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    #accountSettings div.accountSettingsContainer div.wrapper div.leftContainer {
        width: 39%;
        margin: 0 auto 42px;
    }
    #accountSettings div.accountSettingsContainer div.wrapper div.rightContainer {
        width: 77%;
        margin: 0 auto;
    }
    #accountSettings div.accountSettingsContainer div.wrapper div.leftContainer div.imageContainer {
        /* width: 100%;
         */
         margin: 0 auto 20px;
    width: 200px;
    }
}
@media all and (max-width:768px){
    /* #accountSettings div.accountSettingsContainer {
        background-position-x: -407px;
    } */
    #accountSettings div.accountSettingsContainer div.wrapper div.rightContainer {
        width: 100%;
        margin: 0 auto;
    }
    #accountSettings div.accountSettingsContainer div.wrapper div.leftContainer div.imageContainer {
        /* width: 100%; */
        margin: 0 auto 20px;
    width: 200px;
    }
    #accountSettings div.accountSettingsContainer div.wrapper div.leftContainer {
        width: 51%;
    }
}
@media all and (max-width:640px){
    /* #accountSettings div.accountSettingsContainer {
        background-position-x: -597px;
    } */
    #accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group {
        flex-direction: column;
    }
    #accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group:nth-child(3) {
        flex-direction: column-reverse;
    }
    #accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group div.oneByOne {
        width: 100%;
    }
    #accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group:last-child div.oneByOne p {
        max-width: 100%;
    }
    #accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group:last-child div.oneByOne p {
        max-width: 94%;
    }
    #accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group:last-child {
        flex-direction: row;
    }
    #accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group div.oneByOne button {
        padding: 9px 10px;
        width: 93%;
    }

#accountSettings div.accountSettingsContainer div.wrapper div.leftContainer div.imageContainer {
    /* width: 100%; */
    margin: 0 auto 20px;
    width: 200px;
}
}
@media all and (max-width:480px){
    #accountSettings div.accountSettingsContainer div.wrapper div.leftContainer {
        width: 72%;
    }
    #accountSettings div.accountSettingsContainer div.wrapper div.leftContainer div.imageContainer {
        /* width: 100%; */
        margin: 0 auto 20px;
    width: 200px;
    }
    #accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group:last-child{
        flex-direction: column;
        justify-content: center;
    }
    #accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group:last-child div.oneByOne {
        width: 50%;
        margin: 0 auto 10px;
    }
    #accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group div.oneByOne button {
        padding: 9px 10px;
        width: 100%;
    }
    #accountSettings div.accountSettingsContainer div.wrapper div.rightContainer form div.form-group:last-child div.oneByOne:last-child {
        margin-right: 0;
        margin: 0 auto;
    }
    #accountSettings h1.heading {
        font-size: 30px;
    }
    #accountSettings div.accountSettingsContainer div.wrapper div.leftContainer {
        width: 56%;
    }
}
@media all and (max-width:360px){
    #accountSettings h1.heading {
        font-size: 28px;
    }
    
    #accountSettings div.accountSettingsContainer div.wrapper div.leftContainer {
        width: 56%;
    }
}
@media all and (max-width:320px){
    
}