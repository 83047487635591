div.myList{
    overflow-x: hidden;
}
div.myList .slick-dots {
    position: absolute;
    bottom: 10px !important;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  div.myList .slick-dots li.slick-active button:before {
    opacity: .75 !important;
    color: #fff !important;
  }
  div.myList .slick-dots li button:before {
    font-size: 14px !important;
  }
  div.myList .slick-dots li button:before {
    opacity: 0.25 !important;
    color: #fff !important;
  }
div.myList .slick-prev {
    left: -10px !important;
    z-index: 9 !important;
}
div.myList .slick-next{
    z-index: 9 !important;
    right: -10px !important;
}
div.myList{
  padding-top: 150px;

}
div.myList h1.heading{
    color: #fff;
    width: 85%;
    margin: 0 auto;
}
div.myList div.slickSection .slick-list{
    overflow: visible !important;
}
div.myList div.slickSection .slick-prev{
    left: -66px !important;
  width: 11% !important;
  height: 100% !important;
  background-image: url(../../assets//images/carousel\ gradient\ -\ l.svg) !important;
  background-repeat: no-repeat;
  background-position: center;
}
div.myList div.slickSection .slick-next{
    right: -66px !important;
    width: 11% !important;
    height: 100% !important;
    background-image: url(../../assets//images/carousel\ gradient\ -\ r.svg) !important;
    background-repeat: no-repeat;
    background-position: center;
  }
div.myList div.slickSection div.shows{
    margin-right: 20px;
  transition: all 0.5s ease 0s;
  max-height: 250px;
  padding: 20px 0;
}
div.myList div.slickSection div.shows:hover{
    transform: scale(1.1);
    opacity: 1;
    cursor: pointer;
}
div.myList div.slickSection div.shows div.imageContainer{
    width: 248px;
  transition: 0.3s ease-in-out;
  margin: 0 auto;
}
div.myList div.slickSection div.shows div.imageContainer img{
    display: block;
    width: 100%;
    border-radius: 10px;
}
div.myList div.slickSection div.shows h4.title{
    color: var(--text-color) !important;
  font-size: 16px;
  margin-top: 6px;
  padding-left: 14px;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div.myList div.slickSection div.shows:hover  h4.title {
    visibility: hidden;
}
div.myList div.slickSection div.shows div.descriptionContainer {
  background: url(../../assets/images/bg-header.png);
  width: 226px;
  padding: 8px 10px;
  box-sizing: border-box;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s ease, opacity 0.3s ease;
  z-index: 1;
  top: 73%;
  margin-left: 15px;
}
div.myList div.slickSection div.shows:hover div.descriptionContainer {
    visibility: visible; 
    opacity: 1; 
}
div.myList div.slickSection div.shows div.descriptionContainer h4.title{
    color:var(--white-color) !important;
    font-size: 14px;
    margin-top: 6px;
    padding-left: 0;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    visibility: visible; 
    opacity: 1; 
}
div.myList div.slickSection div.shows div.descriptionContainer span p{
    font-size: 12px;
    color: var(--text-color);
    margin: 0;
    display: -webkit-box; 
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; 
    text-overflow: ellipsis;
}
div.myList div.myReminderSection {
   
}
div.myList div.myReminderSection div.allReminders {
    background:transparent linear-gradient(180deg, #001332 0%, #00191500 10%) 0% 0% no-repeat padding-box;
   
}
div.myList div.myReminderSection h1.reminder {
   color: #fff;
    width: 85%;
    margin: 0 auto 30px;
}
div.myList div.myReminderSection div.reminderContainer{
    
    color: #fff;
    padding: 15px 0 30px;
}
div.myList div.myReminderSection div.showsContainer{
   
}
div.myList div.myReminderSection div.showsContainer div.shows{
    margin-bottom: 30px;
}
div.myList div.myReminderSection div.showsContainer div.shows:last-child{
    margin-bottom: 0;
}
div.myList div.myReminderSection div.showsContainer div.shows h3{
    font-size: 25px;
   
}
div.myList div.myReminderSection div.showsContainer div.shows div.showContainer{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.leftContainer{
    width: 28%;
   
}
div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.leftContainer div.imageContainer{
    width: 100%;
   
}
div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.leftContainer div.imageContainer img{
    width: 100%;
    display: block;
    border-radius: 10px;
}
div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer{
    width: 70%;

}
div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer h1.title{
    margin-bottom: 10px;
    
}
div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer p.description{
    margin-bottom: 20px;
    max-width: 60%;
}
div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer{
    display: flex;
    
}
div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button{
    background-color: transparent;
    padding: 8px 40px;
    border: 2px solid #fff;
    border-radius: 30px;
    color: #fff;
    font-size: 18px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    margin-right: 20px;
}
div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button:hover{
    background-color: #213a64;

}
div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button:last-child{
    display: flex;
    align-items: center;
    margin-right: 0;

}
div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button img{
    width: 18px;
    margin-left: 6px;
}
@media all and (max-width:1600px){
    div.myList div.slickSection div.shows div.imageContainer {
        width: 224px;
    }
    div.myList div.slickSection div.shows div.descriptionContainer {
        width: 224px;
    }
}
@media all and (max-width:1400px){
    div.myList div.slickSection div.shows div.imageContainer {
        width: 232px;
    }
    div.myList div.slickSection div.shows div.descriptionContainer {
        width: 232px;
        left: 9px;
    }
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer h1.title {
        font-size: 24px;
    }
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer {
        width: 61%;
    }
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.leftContainer {
        width: 36%;
    }
    
    
}
@media all and (max-width:1280px){
    div.myList div.slickSection div.shows div.imageContainer {
        width: 229px;
    }
    div.myList div.slickSection div.shows div.descriptionContainer {
        width: 229px;
        left: -10px;
    }
    div.myList div.slickSection .slick-prev {
        left: -24px !important;
    }
    div.myList div.slickSection .slick-next {
        right: -24px !important;
    }
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer p.description {
        max-width: 90%;
    }
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer h1.title {
        font-size: 22px;
    }
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button {
        
        font-size: 15px;
    }
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer h1.title {
        margin-bottom: 3px;
    }
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer p.description {
        margin-bottom: 12px;
    }
    /* div.myList div.slickSection div.shows div.imageContainer {
        width: 203px;
        transition: 0.3s ease-in-out;
        margin: 0 auto;
    } */
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer {
        /* width: 60%; */
    }
}
@media all and (max-width:980px){
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.leftContainer {
        width: 44%;
    }
    div.myList div.slickSection div.shows div.descriptionContainer {
        left: 34px;
    }
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer {
        width: 54%;
    }
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer p.description {
        max-width: 100%;
    } 
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer {
        /* width: 65%; */
    }
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer {
        flex-direction: column;
    }
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.leftContainer {
        width: 100%;
    }
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer {
        width: 100%;
        margin-top: 30px;
    }
}
@media all and (max-width:768px){
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer {
        flex-direction: column;
    }
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.leftContainer {
        width: 100%;
    }
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer {
        width: 100%;
        margin-top: 30px;
    }
    .wrapper {
        width: 96%;
        margin: 0 auto;
    }
    div.myList div.slickSection div.shows div.imageContainer {
        width: 90%;
    }
    div.myList div.slickSection div.shows div.descriptionContainer {
        width: 90%;
        left: 4px;
        top: 83%;
    }    
}
@media all and (max-width:640px){
    div.myList div.slickSection .slick-prev {
        left: 12px !important;
        background-size: cover !important;
    }
    div.myList div.slickSection .slick-next {
        right: 12px !important;
        background-size: cover !important;
    }
    div.myList div.slickSection div.shows div.descriptionContainer {
      display: none;
    }
    div.myList div.slickSection div.shows:hover  h4.title {
        padding-left: 40px;
        visibility:visible;
    }
}
@media all and (max-width:480px){
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer {
        flex-direction: column;
    }
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button {
        margin-right: 0;
        margin-bottom: 10px;
        width: 67%;
    }
    div.myList div.myReminderSection div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button:last-child {
        margin-bottom: 0;
    }
}