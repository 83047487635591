/* .no-active{
  display: none;
} */
::placeholder{
  font-size: 16px;
}
#header {
  position: relative;
  z-index: 999;
}
#header div.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  z-index: 9;
  animation: overlayAnimation 0.3s ease-in-out;
}
#header div.overlay.active {
  display: block;
  animation: overlayAnimation 0.3s ease-in-out;
}
#header div.wrapper {
  padding: 0 20px;
}
#header div.notification{
  position: fixed;
  z-index: 99;
  color: #000;
  width: 15%;
  border-radius: 4px;
  right: 130px;
  top: 90px;
  color: #fff;
  background-color: #3b66af;
  padding-right: 5px;
  border: 1px solid #fff;
  display: none;
}
#header div.notification.active{
  display: block;
  animation: slideIn 0.1s ease-in-out;
}
#header div.notification div.notificationItem{
  display: flex;
  align-items: center;
  padding: 4px;
  cursor: pointer;
}
#header div.notification div.notificationItem div.left{
  width: 30%;
  margin-right: 7px;
}
#header div.notification div.notificationItem div.left div.imageContainer{
  width: 100%;
}
#header div.notification div.notificationItem div.left div.imageContainer img{
  width: 100%;
  display: block;
  border-radius: 3px;
}
#header div.notification div.notificationItem div.right {
  width: 70%;
  text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
#header div.notification div.notificationItem div.right div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#header div.notification div.notificationItem div.right div.head h2.heading {
  font-size: 14px;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#header div.notification div.notificationItem div.right div.head span {
  font-size: 10px;
}
#header div.notification div.notificationItem div.right span.desc {
  font-size: 10px;
}
#header div.wrapper div.containers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 134px;
  background: url(../../assets/images/bg-header.png) repeat-x top center;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 8;
  padding: 0 37px;
}
#header div.wrapper div.active {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 134px;
  background: linear-gradient(rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
  transition: all 0.5s ease;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  padding: 0 37px;

}
#header div.wrapper div.leftContainer {
}
#header div.wrapper div.leftContainer.responsive {
  display: none;
}
#header div.wrapper div.leftContainer div.imageContainer {
  transition: 0.4s ease-in-out;
  width: 120px;
}
#header div.wrapper div.leftContainer div.imageContainer.small {
  width: 52px;
}
#header div.wrapper div.leftContainer div.imageContainer img {
  width: 100%;
  display: block;
  cursor: pointer;
}
#header div.wrapper div.centerContainer {
}
#header div.wrapper div.centerContainer.animation {
  animation: slideLeft 0.6s ease-in-out;
}
#header div.wrapper div.centerContainer nav {
}
#header div.wrapper div.centerContainer nav ul.navBar {
  display: flex;
  align-items: center;
}
#header div.wrapper div.centerContainer nav ul.navBar a {
  margin-right: 43px;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}
#header div.wrapper div.centerContainer nav ul.navBar a:last-child {
  margin-right: 0;
}
#header div.wrapper div.centerContainer nav ul.navBar li.navLinks {
  margin-right: 40px;
  font-size: 16px;
  color: #fbfbfb;
  cursor: pointer;
}
#header
  div.wrapper
  div.centerContainer
  nav
  ul.navBar
  a:nth-child(2)
  li.navLinks {
  display: flex;
  align-items: center;
}
#header
  div.wrapper
  div.centerContainer
  nav
  ul.navBar
  li.navLinks
  div.imageContainer {
  width: 12px;
  margin-right: 10px;
  padding-bottom: 6px;
}
#header
  div.wrapper
  div.centerContainer
  nav
  ul.navBar
  li.navLinks
  div.imageContainer
  img {
  display: block;
  width: 100%;
}
#header div.wrapper div.centerContainer nav ul.navBar li.navLinks span {
  padding-bottom: 7px;
  display: inline-block;
  color: #b6becb;
  transition: 0.3s ease-in-out;
}
#header div.wrapper div.centerContainer nav ul.navBar li.navLinks:hover span {
  
  color: var(--white-color)
}
#header div.wrapper div.centerContainer nav ul.navBar li.navLinks span.active {
  border-bottom: 1px solid #fff;
  color: #fbfbfb;
}
#header div.wrapper div.centerContainer nav ul.navBar li.navLinks:last-child {
  margin-right: 0;
}
#header div.wrapper div.rightContainer {
}
#header div.wrapper div.rightContainer ul.icons {
  display: flex;
  align-items: center;
}
#header div.wrapper div.rightContainer ul.icons li.icon {
  margin-right: 34px;
  width: 28px;
  cursor: pointer;
}
#header div.wrapper div.rightContainer ul.icons li.icon.user-icon {
  margin-right: 0;
  
}
#header div.wrapper div.rightContainer ul.icons li.search {
  margin-right: 30px;
  animation: fadeIn 0.6s ease-in-out;
}
#header div.wrapper div.rightContainer ul.icons li.search form {
  display: flex;
  background: #010915;
  border: 1px solid #3b66af;
  padding: 5px 9px;
  border-radius: 5px;
  
}
#header div.wrapper div.rightContainer ul.icons li.search form input {
  background: transparent;
  border: none;
  outline: none;
}
#header div.wrapper div.rightContainer ul.icons li.search form button {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}
#header div.wrapper div.rightContainer ul.icons li.search form button img {
  width: 24px;
}
#header div.wrapper div.rightContainer ul.icons li.icon:last-child {
  margin-right: 0;
}
#header div.wrapper div.rightContainer ul.icons li.icon.user-icon {
  width: 40px;
  display: flex;
  align-items: center;
}
#header div.wrapper div.rightContainer ul.icons li.icon img {
  display: block;
  width: 100%;
}
#header div.wrapper div.rightContainer ul.icons li.icon img.arrow {
 margin-left: 4px;
 width: 15px !important;
 height: 15px !important;
 transition: 0.4s ease-in-out;
}
#header div.wrapper div.rightContainer ul.icons li.icon img.arrow.rotate {
  transform: rotate(180deg);
 }

#header div.wrapper div.rightContainer ul.icons li.icon.mobile {
  display: none;
}

#header div.userDetails {
  position: fixed;
  background-color: var(--button-color);
  z-index: 999;
  color: var(--text-color);
  width: 23%;
  border: 2px solid #fff;
  border-radius: 10px;
  right: 16px;
  top: 125px;
  display: none;
  
}
#header div.userDetails.loggedIn{
  width: 28%;
}
#header div.userDetails.active {
    display: block;
    animation: slideIn 0.3s ease-in-out;
  }
#header div.userDetails ul {
  
}
#header div.userDetails ul a {
  display: block;
  color: #fff;
}
#header div.userDetails ul li{
    padding: 8px;
    font-size: 20px;
}
#header div.userDetails ul a:first-child  li{
    border-bottom: 2px solid #fff;
}
#header div.userDetails div.isLoggedIn { 
  display: flex;
  /* justify-content: space-between; */
  
  
}

#header div.userDetails div.isLoggedIn ul { 

}

#header div.userDetails div.isLoggedIn ul li { 

}

#header div.userDetails div.isLoggedIn div.leftContainer { 
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
  border-right: 1px solid #fff;
  padding: 6px;
}

#header div.userDetails div.isLoggedIn div.leftContainer div.left { 
}

#header div.userDetails div.isLoggedIn div.leftContainer div.left div.imageContainer { 
  width: 80px;
}

#header div.userDetails div.isLoggedIn div.leftContainer div.left div.imageContainer img { 
  width: 100%;
  display: block;
}

#header div.userDetails div.isLoggedIn div.leftContainer div.center { 

}

#header div.userDetails div.isLoggedIn div.leftContainer div.center h2 { 
font-size: 13px;
font-weight: normal;
color: var(--text-color) !important;
}

#header div.userDetails div.isLoggedIn div.leftContainer div.center p { 
  font-size: 10px;
}

#header div.userDetails div.isLoggedIn div.leftContainer div.right { 

}

#header div.userDetails div.isLoggedIn div.leftContainer div.right div.playIconContainer { 
  border: 1px solid #fff;
  border-radius: 20px;
  padding: 6px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#header div.userDetails div.isLoggedIn div.leftContainer div.right div.playIconContainer img { 

}

#header div.userDetails div.isLoggedIn div.rightContainer { 
  width: 35%;

}

#header div.userDetails div.isLoggedIn div.rightContainer div.AccountSettings { 
  border-bottom: 1px solid #fff;
  padding-left: 6px;
  padding-bottom: 6px;
}

#header div.userDetails div.isLoggedIn div.rightContainer div.AccountSettings span { 
  font-size: 14px;
}

#header div.userDetails div.isLoggedIn div.rightContainer div.Log { 
  padding-left: 6px;
  padding-bottom: 6px;
}

#header div.userDetails div.isLoggedIn div.rightContainer div.Log span { 
  font-size: 14px;
}

#header div.radio { 
  position: fixed;
  background-color: #ffffff;
  z-index: 999;
  color: #000;
  width: 29%;
  border-radius: 10px;
  right: 147px;
  top: 83px;
  padding: 15px 20px;
  display: none;
}
#header div.radio.active{
  display: block;
  animation: slideIn 0.1s ease-in-out;
}
#header div.radio div.top { 
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#header div.radio div.top div.left { 

}
#header div.radio div.top div.left h2 { 
  font-size: 20px;
}
#header div.radio div.top div.right { 

}
#header div.radio div.top div.right div.closeContainer { 
  width: 12px;
  cursor: pointer;
}
#header div.radio div.top div.right div.closeContainer img { 
  width: 100%;
  display: block;
}
#header div.radio div.center { 
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border-bottom: 1px solid #bebebe;
  padding-bottom: 10px;
}
#header div.radio div.center div.left { 
  width: 11%;
}
#header div.radio div.center div.left div.imageContainer { 
  width: 100%;
  cursor: pointer;
}
#header div.radio div.center div.left div.imageContainer img { 
  width: 100%;
  display: block;
}
#header div.radio div.center div.right { 
  width: 86%;
}
#header div.radio div.center div.right h3 { 
  font-size: 16px;
}
#header div.radio div.center div.right p { 
  font-size: 15px;
  margin-bottom: 10px;
}
#header div.radio div.center div.right audio { 
  width: 100%;
}
#header div.radio div.bottom { 
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
#header div.radio div.bottom div.left { 
  width: 50%;
}
#header div.radio div.bottom div.left div.imageContainer { 
  width: 50px;
  cursor: pointer;
}
#header div.radio div.bottom div.left div.imageContainer img { 
  width: 100%;
  display: block;
  transition: 0.2s ease-in-out;
}
#header div.radio div.bottom div.left div.imageContainer:hover img { 
  opacity: 0.8;
}
#header div.radio div.bottom div.right { 
  display: flex;
  justify-content: space-between;
  width: 48%;
}
#header div.radio div.bottom div.right div.volumeDown { 
  width: 20px;
  cursor: pointer;
}
#header div.radio div.bottom div.right div.volumeDown img { 
  width:100%;
  display: block;
  transition: 0.2s ease-in-out;
}
#header div.radio div.bottom div.right div.volumeDown:hover img { 
  opacity: 0.8;
}
#header div.radio div.bottom div.right div.volumeUp { 
  width: 20px;
  cursor: pointer;
}
#header div.radio div.bottom div.right div.volumeUp img { 
  width:100%;
  display: block;
  transition: 0.2s ease-in-out;
}
#header div.radio div.bottom div.right div.volumeUp:hover img { 
 opacity: 0.8;
}

#header div.mobileMenu {
  position: fixed;
  z-index: 999;
  background-color: #213a64;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  padding-top: 15px;
  transform: translateX(700px);
  transition: 0.3s ease-in-out;
  visibility: hidden;
}
#header div.mobileMenu div.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 15px;
  border-bottom: 1px solid #1f2b41;
}
#header div.mobileMenu div.top h3.heading {
  color: #7a89a2;
  font-weight: 600;
  font-size: 18px;
  
}
#header div.mobileMenu div.top div.imageContainer {
  width: 15px;
}
#header div.mobileMenu div.top div.imageContainer img {
  display: block;
  width: 100%;
}
#header div.mobileMenu ul.menu {
  padding-left: 20px;
  color: #fff;
}
#header div.mobileMenu ul.menu a{
  display: block;
  color: #fff;
}
#header div.mobileMenu ul.menu li {
  padding: 10px 10px 10px 0;
  border-bottom: 1px solid #1f2b41;
}
#header div.mobileMenu ul.menu a:nth-child(2) li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#header div.mobileMenu ul.menu a:nth-child(2) li img{
  width: 10px;
}
#header div.mobileMenu ul.menu li span {
  
}
#header div.mobileMenu ul.menu li.user-mobile {
  display: none;
}
#header div.mobileMenu ul.menu li.user-mobile div.imageContainer {
  width: 20px;
}
#header div.mobileMenu ul.menu li.user-mobile div.imageContainer img {
  width: 100%;
  display: block;
}
#header div.mobileMenu ul.menu li.user-mobile div.arrowContainer {
  width: 15px;
}
#header div.mobileMenu ul.menu li.user-mobile div.arrowContainer img {
  width: 100%;
  display: block;
}

@media all and (max-width:1980px){
  #header div.mobileMenu {
    right: -290px;
  }
}

@media all and (max-width:1600px){
  #header div.mobileMenu {
    right: -100px;
  }
  #header div.wrapper div.centerContainer nav ul.navBar a {
    margin-right: 33px;
  }
  
}
@media all and (max-width:1400px){
  #header div.wrapper div.centerContainer nav ul.navBar li.navLinks {
    font-size: 14px;
}
#header div.wrapper div.centerContainer nav ul.navBar a {
  margin-right: 25px;
}
#header div.wrapper div.rightContainer ul.icons li.icon {
  margin-right: 28px;
}
}

@media all and (max-width: 1280px) {
  #header div.wrapper div.leftContainer div.imageContainer {
    width: 70px;
  }
  #header div.wrapper div.centerContainer nav ul.navBar li.navLinks {
    margin-right: 21px;
    font-size: 12px;
  }
  #header div.wrapper div.rightContainer ul.icons li.icon {
    margin-right: 17px;
    width: 18px;
  }
  #header div.wrapper div.centerContainer nav ul.navBar a {
    margin-right: 19px;
  }
  #header div.wrapper div.centerContainer nav ul.navBar a:last-child {
    margin-right: 0;
  }
  #header div.radio {
    width: 50%;
    padding: 7px 20px;
  }
  #header div.wrapper div.rightContainer ul.icons li.search {
    margin-right: 16px;
    width: 53%;
    position: relative;
  }
  #header div.wrapper div.rightContainer ul.icons li.search form {
    padding: 6px 4px;
    background-color: #010915;
  }
  #header div.wrapper div.rightContainer ul.icons li.search form input {
    display: block;
    width: 100%;
  }
  #header div.wrapper div.rightContainer ul.icons li.search form input::placeholder {
    font-size: 12px;
  }
  #header div.wrapper div.rightContainer ul.icons li.search form button {
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
  }
  #header div.wrapper div.rightContainer ul.icons li.search form button img {
    width: 15px
}
  #header div.wrapper div.centerContainer {
    /* display: none; */
  }
  #header div.wrapper div.leftContainer.responsive {
    display: block;
  }
  /* #header div.wrapper div.leftContainer {
    display: none;
  } */
  /* #header div.wrapper div.rightContainer ul.icons li.icon.mobile {
    display: block;
    width: 25px;
  } */
  #header div.wrapper div.rightContainer ul.icons li.icon.mobile:last-child {
    width: 30px;
  }
  #header div.wrapper div.rightContainer ul.icons li.icon.user-icon {
    display: none;
  }
  #header div.wrapper div.rightContainer ul.icons li.icon.user-icon.mobile {
    width: 41px;
    display: block;
  }
  #header div.mobileMenu.active {
    transform: translateX(0);
  }
  #header div.userDetails.loggedIn {
    width: 44%;
  }
  #header div.wrapper div.containers {
    padding: 0 21px;
  }
  #header div.wrapper div.active {
    padding: 0 21px;
  }
}
@media all and (max-width: 980px) {
  #header div.mobileMenu {
    visibility: visible;
}
  #header div.wrapper div.leftContainer div.imageContainer {
    width: 112px;
}
  #header div.wrapper div.leftContainer div.imageContainer.small {
    width: 112px;
  }
  #header div.wrapper div.rightContainer ul.icons li.icon.user-icon{
    margin-right: 17px;
  }
  #header div.wrapper div.rightContainer ul.icons li.icon.mobile:last-child {
    display: block;
    width: 20px !important;
  }
  #header div.wrapper div.centerContainer {
    display: none;
  }
  #header div.wrapper div.rightContainer ul.icons li.icon.user-icon {
    display: none;
  }
  #header div.wrapper div.rightContainer ul.icons li.icon:first-child {
    width: 23px;
  }
  #live #spotlight div.donate {
    display: none;
}
#header div.notification {
  width: 22%;
}
#header div.userDetails.loggedIn {
  width: 55%;
}
}
@media all and (max-width: 768px) {
  #header div.mobileMenu {
    right: 0;
}
  #header div.userDetails.loggedIn {
    width: 65%;
  }
}
@media all and (max-width: 640px) {
  #header div.wrapper div.rightContainer ul.icons li.icon.user-icon {
    display: none;
  }
  #header div.wrapper div.rightContainer ul.icons li.icon:nth-child(4) {
   /* display: none; */
}
  #header div.wrapper div.rightContainer ul.icons li.icon {
    width: 25px;
  }
  #header div.radio {
    width: 68%;
    right: 16px;
    padding: 15px 20px;
  }
  #header div.wrapper div.rightContainer ul.icons li.icon.user-icon.mobile {
    display: none;
  }
  #header div.radio div.bottom div.left div.imageContainer {
    width: 43px;
  }
  #header div.wrapper div.rightContainer ul.icons li.icon.user-icon.mobile {
    width: 48px;
  }
  #header div.wrapper div.rightContainer ul.icons li.icon:nth-child(3) {
    margin-bottom: 6px !important;
  }
  #header div.wrapper div.rightContainer ul.icons li.icon.mobile:last-child {
    width: 35px;
  }
  #header div.mobileMenu {
    width: 64%;
  }
  #header div.mobileMenu ul.menu li.user-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media all and (max-width: 480px) {
  #header div.wrapper div.leftContainer div.imageContainer {
    width: 112px;
}
#header div.wrapper div.rightContainer ul.icons li.search {
  margin-right: 0;
  position: fixed;
  top: 114px;
  right: 50px;
  background: #000;
}
#header div.radio {
  width: 87%;
}
#header div.wrapper div.rightContainer ul.icons li.icon.user-icon.mobile {
  display: none;
}
}
@media all and (max-width: 360px) {
}
@media all and (max-width: 321px) {
}



@keyframes slideIn {
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
    100% {
        transform: translateY(0rem);
        opacity: 1;
    }
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
}

@keyframes slideLeft {
  0% {
      transform: translateX(1rem);
      opacity: 0;
  }
  100% {
      transform: translateX(0rem);
      opacity: 1;
  }
  0% {
      transform: translateX(1rem);
      opacity: 0;
  }
}

@keyframes overlayAnimation {
  0% {
      transform: translateX(1rem);
      opacity: 0;
  }
  100% {
      transform: translateX(0rem);
      opacity: 1;
  }
  0% {
      transform: translateX(1rem);
      opacity: 0;
  }
}

@keyframes fadeIn{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}