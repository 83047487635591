:root{
  --button-color:#010915;
  --hover-color:#213a64;
  --active-color:#3b66af;
  --text-color:#b6becb;
  --white-color:#fbfbfb;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  
}
h1,h2,h3,h4,h5,h6{
  color: var(--white-color)!important;
}
p{
  color: var(--text-color) !important;
}

.wrapper{
  width: 85%;
  margin: 0 auto;
}
body{
  background-color: #010915;
  font-family: Arial, Helvetica, sans-serif;
}
.slick-track {
  margin-left: unset !important;
}

.slick-prev,.slick-next{
  z-index: 9 !important;
}
.slick-prev::before{
  content: "1" !important;
  color: transparent !important;
  background-image: url(./assets/images/arrow_l.svg) !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  opacity: 1 !important;
  font-size: 31px !important;
}
.slick-next::before{
  content: "1" !important;
  color: transparent !important;
  background-image: url(./assets/images/arrow_r.svg) !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  opacity: 1 !important;
  font-size: 31px !important;

}
div.gradientBg{
  background: url(./assets/images/gradient\ overlay.svg) repeat-x center bottom;
  background-size: contain;
  position: absolute;
  left: 0;
  bottom: 91px;
  width: 100%;
  z-index: 9;
  height: 162px;
}
button.removeList{
  width: 13rem !important;
}
@media all and (max-width:1980px){
  button.removeList{
    width: 14rem !important;
  }
}
@media all and (max-width:1400px){
  button.removeList {
    width: 13rem !important;
}
}
@media all and (max-width:1200px){
  button.removeList {
    width: 13rem !important;
    padding: 6px 26px 6px 14px !important;
}
}
@media all and (max-width:980px){
  button.removeList {
    width: 13rem !important;
}
}
@media all and (max-width:768px){
  button.removeList {
    width: 13rem !important;
}
}
@media all and (max-width:640px){
  button.removeList {
    width: 13rem !important;
}
}
@media all and (max-width:480px){
  button.removeList {
    width: 13rem !important;
}
}
@media all and (max-width:360px){
  button.removeList {
    width: 13rem !important;
}
}