#allShows {
    padding: 130px 0 50px;
    color: #fff;
    position: relative;
}
.no-active{
    display: none;
}
#allShows h1 {
    font-size: 25px;
}
#allShows ul {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
#allShows ul li {
    width: 23%;
    margin-bottom: 20px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    margin-right: 20px;
}
#allShows ul li:nth-child(4n) {
    margin-right: 0;
}
#allShows ul li:hover {
    transform: scale(1.1);
}
#allShows ul li div.imageContainer {
    width: 100%;
}
#allShows ul li div.imageContainer img {
    display: block;
    width: 100%;
}
#allShows ul li span {
    margin-top: 10px;
    display: inline-block;
}
#allShows div.bottom {
    background: url("../../../assets//images//shedule-gradient.png") repeat-x center bottom;
    background-size: contain;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
}
#allShows div.bottom div.downArrowContainer {
    width: 55px;
    margin: 0 auto;
    cursor: pointer;
}
#allShows div.bottom div.downArrowContainer img {
    display: block;
    width: 100%;
}

/* responsive-section */

@media all and (max-width:1280px){
    #allShows h1 {
        font-size: 21px;
    }
}
@media all and (max-width:980px){
    #allShows ul li {
        width: 30%;
    }
    #allShows ul li:nth-child(4n) {
        margin-right: 20px;
    }
    #allShows ul li:nth-child(3n) {
        margin-right: 0;
    }
}
@media all and (max-width:768px){
    #allShows ul li {
        width: 46%;
    }
    #allShows ul li:nth-child(3n) {
        margin-right: 20px;
    }
    #allShows ul li:nth-child(2n) {
        margin-right: 0;
    }
}
@media all and (max-width:640px){
    #allShows ul li {
        width: 100%;
        margin-right: 0;
    }
    #allShows ul li:nth-child(3n) {
        margin-right: 0;
    }
}
@media all and (max-width:480px){
    
}
@media all and (max-width:360px){
    
}
@media all and (max-width:320px){
    
}
