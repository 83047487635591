.no-active {
  display: none;
}

#live {
  padding: 130px 0 0;
}

#live div.wrapper {
}

#live #spotlight {
  position: relative;
}

#live #spotlight div.thumbnailContainer {
  width: 100%;
  background-color: #000;
  cursor: pointer;
  position: relative;
  /* height: 726.36px; */
}

#live #spotlight div.thumbnailContainer img.bg {
  display: block;
  width: 100%;
  margin: 0 auto;
  height: 100%;
}

#live #spotlight div.thumbnailContainer img.play-icon {
  width: 68px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}

#live #spotlight div.thumbnailContainer img.play-icon:hover {
  -webkit-filter: drop-shadow(0 0 3px #fff);
  filter: drop-shadow(0 0 3px #fff);
}

#live #spotlight div.videoContainer {
  width: 100%;
  /* height: 486.16px; */
}

#live #spotlight div.videoContainer div.Vplayer {
  position: relative;
  z-index: 9;
  width: 100%;
  height: 100%;
}
#live #spotlight div.videoContainer div.Vplayer div.closeIcon {
  display: none;
}

#live #spotlight div.videoContainer div.Vplayer.pipMode:hover div.closeIcon {
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9;
  background-color: #6f7072;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
#live #spotlight div.videoContainer div.Vplayer.pipMode div.closeIcon svg {
    stroke: #fff !important;
    width: 10px !important;
    height: 10px !important;
}


#live #spotlight div.videoContainer div.Vplayer.pipMode {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  width: 22%;
  right: 0;
  z-index: 999999999;
  height: 200px;
  
}

#live #spotlight div.videoContainer div.Vplayer video {
  width: 100%;
  height: 100%;
  display: block;
}

#live #spotlight div.nowPlaying {
  width: 100%;
  background-color: #213a64;
  color: #fff;
  padding: 25px 25px;
  border-radius: 5px;
  margin-top: 15px;
  
}

#live #spotlight div.nowPlaying p {
 margin-top: 20px;
  
}

#live #spotlight div.nowPlaying div.top {
  display: flex;
  align-items: center;
  
}

#live #spotlight div.donate {
  position: absolute;
  right: 0;
  bottom: 125px;
  z-index: 8;
  cursor: pointer;
  display: none;
}

#live #spotlight div.donate div.donateButton {
  width: 32px;
}

#live #spotlight div.donate div.no-active {
  display: none;
}

#live #spotlight div.donate div.donateButton img {
  width: 100%;
  display: block;
}

#live #spotlight div.donate div.clickToDonate {
  width: 279px;
}

#live #spotlight div.donate div.no-active {
  display: none;
}

#live #spotlight div.donate div.clickToDonate img {
  width: 100%;
}

#live #spotlight div.nowPlaying div.top h3 {
  font-size: 30px;
  width: 28%;
}

#live #spotlight div.nowPlaying div.top span {
  font-size: 30px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#live #upComing {
  color: #fff;
  margin-top: 40px;
}

#live #upComing h1.upTitle {
  width: 85%;
  margin: 0 auto 30px;
}

#live #upComing div.allContainers {
  background: url("../../assets/images/comingup-gradient-live.png") repeat-x top
    center;
  background-size: cover;
  padding: 20px 15px 40px;
}

#live #upComing div.upNextShowContainer {
}

#live #upComing div.upNextShowContainer h3 {
  font-size: 25px;
}

#live #upComing div.upNextShowContainer div.showContainer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#live #upComing div.upNextShowContainer div.showContainer div.leftContainer {
  width: 28%;
}

#live
  #upComing
  div.upNextShowContainer
  div.showContainer
  div.leftContainer
  div.imageContainer {
  width: 100%;
}

#live
  #upComing
  div.upNextShowContainer
  div.showContainer
  div.leftContainer
  div.imageContainer
  img {
  width: 100%;
  display: block;
  border-radius: 10px;
}

#live #upComing div.upNextShowContainer div.showContainer div.rightContainer {
  width: 70%;
}

#live
  #upComing
  div.upNextShowContainer
  div.showContainer
  div.rightContainer
  h1.title {
  margin-bottom: 10px;
  padding: 0;
  font-size: 25px;
}

#live
  #upComing
  div.upNextShowContainer
  div.showContainer
  div.rightContainer
  p.description {
  margin-bottom: 20px;
  max-width: 60%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

#live
  #upComing
  div.upNextShowContainer
  div.showContainer
  div.rightContainer
  div.buttonContainer {
  display: flex;
}

#live
  #upComing
  div.upNextShowContainer
  div.showContainer
  div.rightContainer
  div.buttonContainer
  button {
  background-color: var(--button-color);
  padding: 8px 40px;
  border: 2px solid #fff;
  border-radius: 30px;
  color: #fff;
  font-size: 18px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  margin-right: 20px;
}

#live
  #upComing
  div.upNextShowContainer
  div.showContainer
  div.rightContainer
  div.buttonContainer
  button:hover {
  background-color: #213a64;
}

#live
  #upComing
  div.upNextShowContainer
  div.showContainer
  div.rightContainer
  div.buttonContainer
  button:last-child {
  display: flex;
  align-items: center;
  margin-right: 0;
}

#live
  #upComing
  div.upNextShowContainer
  div.showContainer
  div.rightContainer
  div.buttonContainer
  button:last-child
  img {
  width: 18px;
  margin-left: 6px;
}

#live #upComing div.comingUpShowContainer {
  margin-top: 30px;
}

#live #upComing div.comingUpShowContainer h1.comingUp {
  margin-bottom: 10px;
}

#live #upComing div.comingUpShowContainer div.weeklyContainer {
  width: 80%;
  margin: 0 auto;
}

#live #upComing div.comingUpShowContainer div.weeklyContainer {
}

#live #upComing div.comingUpShowContainer div.weeklyContainer .slick-prev {
  left: -44px !important;
  height: 100% !important;
}

#live #upComing div.comingUpShowContainer div.weeklyContainer .slick-next {
  right: -41px !important;
  height: 100% !important;
}

#live #upComing div.comingUpShowContainer div.weeklyContainer div.weeks {
}

#live
  #upComing
  div.comingUpShowContainer
  div.weeklyContainer
  div.weeks
  button.weekDays {
  background-color: transparent;
  padding: 8px 42px;
  border: 2px solid #fff;
  border-radius: 30px;
  color: #fff;
  font-size: 16px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  width: 95%;
}

#live
  #upComing
  div.comingUpShowContainer
  div.weeklyContainer
  div.weeks
  button.weekDays:hover {
  background-color: #213a64;
}

#live #upComing div.allContainers div.earlierShowsContainer {
  margin-top: 30px;
}

#live #upComing div.allContainers div.earlierShowsContainer button {
  background-color: var(--button-color);
  padding: 8px 30px;
  border: 2px solid #fff;
  border-radius: 30px;
  color: #fff;
  font-size: 18px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
}

#live #upComing div.allContainers div.earlierShowsContainer button:hover {
  background-color: #213a64;
}

#live #upComing div.allContainers div.earlierShowsContainer button img {
  width: 18px;
  margin-left: 10px;
}

#live #allShows {
  /* background: url("../../assets/images/comingup-gradient-live.png") repeat-x top center;
    background-size: cover; */
  background: transparent linear-gradient(180deg, #001332 0%, #00191500 10%) 0%
    0% no-repeat padding-box;
  color: #fff;
  padding: 15px 0 30px;
}

#live #allShows div.showsContainer {
}

#live #allShows div.showsContainer div.shows {
  margin-bottom: 30px;
}

#live #allShows div.showsContainer div.shows:last-child {
  margin-bottom: 0;
}

#live #allShows div.showsContainer div.shows h3 {
  font-size: 25px;
}

#live #allShows div.showsContainer div.shows div.showContainer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#live
  #allShows
  div.showsContainer
  div.shows
  div.showContainer
  div.leftContainer {
  width: 28%;
}

#live
  #allShows
  div.showsContainer
  div.shows
  div.showContainer
  div.leftContainer
  div.imageContainer {
  width: 100%;
}

#live
  #allShows
  div.showsContainer
  div.shows
  div.showContainer
  div.leftContainer
  div.imageContainer
  img {
  width: 100%;
  display: block;
  border-radius: 10px;
}

#live
  #allShows
  div.showsContainer
  div.shows
  div.showContainer
  div.rightContainer {
  width: 70%;
}

#live
  #allShows
  div.showsContainer
  div.shows
  div.showContainer
  div.rightContainer
  h1.title {
  margin-bottom: 10px;
}

#live
  #allShows
  div.showsContainer
  div.shows
  div.showContainer
  div.rightContainer
  p.description {
  margin-bottom: 20px;
  max-width: 60%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

#live
  #allShows
  div.showsContainer
  div.shows
  div.showContainer
  div.rightContainer
  div.buttonContainer {
  display: flex;
}

#live
  #allShows
  div.showsContainer
  div.shows
  div.showContainer
  div.rightContainer
  div.buttonContainer
  button {
  background-color: var(--button-color);
  padding: 8px 40px;
  border: 2px solid #fff;
  border-radius: 30px;
  color: #fff;
  font-size: 18px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  margin-right: 20px;
}

#live
  #allShows
  div.showsContainer
  div.shows
  div.showContainer
  div.rightContainer
  div.buttonContainer
  button:hover {
  background-color: #213a64;
}

#live
  #allShows
  div.showsContainer
  div.shows
  div.showContainer
  div.rightContainer
  div.buttonContainer
  button:last-child {
  display: flex;
  align-items: center;
  margin-right: 0;
}

#live
  #allShows
  div.showsContainer
  div.shows
  div.showContainer
  div.rightContainer
  div.buttonContainer
  button:last-child
  img {
  width: 18px;
  margin-left: 6px;
}

#live #allShows div.bottom {
  background: url("../../assets//images//shedule-gradient.png") repeat-x center
    bottom;
  background-size: contain;
  position: absolute;
  left: 0;
  bottom: 26px;
  width: 100%;
  height: 113px;
}

#live #allShows div.bottom div.downArrowContainer {
  width: 55px;
  margin: 63px auto 0;
  cursor: pointer;
}

#live #allShows div.bottom div.downArrowContainer img {
  display: block;
  width: 100%;
}

.activeButtonBgChange {
  background: #050535 !important;
}

/* Responsive Section  */

@media all and (max-width: 1280px) {
  #live #spotlight div.thumbnailContainer img {
    display: block;
    width: 100%;
    margin: 0 auto;
  }

  #live
    #upComing
    div.upNextShowContainer
    div.showContainer
    div.rightContainer
    p.description {
    max-width: 90%;
  }

  #live
    #upComing
    div.comingUpShowContainer
    div.weeklyContainer
    div.weeks
    button.weekDays {
    padding: 8px 37px;
  }

  #live
    #allShows
    div.showsContainer
    div.shows
    div.showContainer
    div.rightContainer
    p.description {
    max-width: 90%;
  }

  #live #spotlight div.nowPlaying div.top span {
    font-size: 25px;
  }

  #live #spotlight div.nowPlaying div.top h3 {
    font-size: 25px;
    width: 30%;
  }
}

@media all and (max-width: 980px) {
  #live #spotlight div.videoContainer div.Vplayer.pipMode {
    width: 33%;
  }
  #live
    #upComing
    div.upNextShowContainer
    div.showContainer
    div.rightContainer
    h1.title {
    font-size: 23px;
  }

  #live #upComing div.upNextShowContainer div.showContainer div.leftContainer {
    width: 44%;
  }

  #live
    #upComing
    div.upNextShowContainer
    div.showContainer
    div.rightContainer
    p.description {
    max-width: 100%;
  }

  #live #upComing div.upNextShowContainer div.showContainer div.rightContainer {
    width: 54%;
  }

  #live
    #upComing
    div.upNextShowContainer
    div.showContainer
    div.rightContainer
    div.buttonContainer
    button {
    font-size: 16px;
    padding: 8px 19px;
  }

  #live
    #allShows
    div.showsContainer
    div.shows
    div.showContainer
    div.leftContainer {
    width: 44%;
  }

  #live
    #allShows
    div.showsContainer
    div.shows
    div.showContainer
    div.rightContainer {
    width: 54%;
  }

  #live
    #allShows
    div.showsContainer
    div.shows
    div.showContainer
    div.rightContainer
    p.description {
    max-width: 100%;
  }

  #live #spotlight div.thumbnailContainer div.donate {
    display: none;
  }

  #live
    #allShows
    div.showsContainer
    div.shows
    div.showContainer
    div.rightContainer
    div.buttonContainer
    button {
    padding: 8px 15px;
  }

  #live #spotlight div.nowPlaying div.top h3 {
    width: 100%;
    margin-bottom: 10px;
  }
  #live #spotlight div.nowPlaying div.top  {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

@media all and (max-width: 768px) {
  #live #upComing div.upNextShowContainer div.showContainer div.leftContainer {
    width: 100%;
  }

  #live #upComing div.upNextShowContainer div.showContainer {
    flex-direction: column;
  }

  #live #upComing div.upNextShowContainer div.showContainer {
    align-items: baseline;
  }

  #live #upComing div.upNextShowContainer div.showContainer div.rightContainer {
    width: 100%;
    margin-top: 30px;
  }

  #live #upComing div.comingUpShowContainer {
    margin-top: 78px;
  }

  #live #allShows div.showsContainer div.shows div.showContainer {
    flex-direction: column;
  }

  #live
    #allShows
    div.showsContainer
    div.shows
    div.showContainer
    div.leftContainer {
    width: 100%;
  }

  #live
    #allShows
    div.showsContainer
    div.shows
    div.showContainer
    div.rightContainer {
    width: 100%;
    margin-top: 30px;
  }

  .wrapper {
    width: 96%;
    margin: 0 auto;
  }

  #live #spotlight div.nowPlaying {
    width: 100%;
    margin: 15px auto 0;
  }
}

@media all and (max-width: 640px) {
  #live #spotlight div.videoContainer div.Vplayer.pipMode {
    width: 49%;
  }
  #live #spotlight div.thumbnailContainer img.play-icon {
    width: 45px;
  }

  #live #spotlight div.nowPlaying h3 {
    font-size: 24px;
    margin-bottom: 8px;
  }

  #live #spotlight div.nowPlaying span {
    font-size: 24px;
  }

  #live
    #upComing
    div.comingUpShowContainer
    div.weeklyContainer
    div.weeks
    button.weekDays {
    width: 89%;
  }

  #live #upComing div.comingUpShowContainer div.weeklyContainer div.weeks {
    width: 100%;
  }

  #live
    #upComing
    div.comingUpShowContainer
    div.weeklyContainer
    div.weeks
    button.weekDays {
    width: 100%;
  }

  #live #spotlight div.nowPlaying {
    width: 100%;
    padding: 20px;
  }
}

@media all and (max-width: 480px) {
  #live #spotlight div.nowPlaying {
    width: 100%;
    flex-direction: column;
  }

  #live
    #allShows
    div.showsContainer
    div.shows
    div.showContainer
    div.rightContainer
    div.buttonContainer
    button:last-child {
    justify-content: center;
  }

  #live
    #upComing
    div.upNextShowContainer
    div.showContainer
    div.rightContainer
    div.buttonContainer
    button:last-child {
    justify-content: center;
  }

  #live #spotlight div.nowPlaying h3 {
    font-size: 22px;
  }

  #live #spotlight div.nowPlaying span {
    font-size: 22px;
  }

  #live #upComing div.upNextShowContainer div.showContainer div.leftContainer {
    width: 100%;
  }

  #live
    #upComing
    div.upNextShowContainer
    div.showContainer
    div.rightContainer
    div.buttonContainer {
    flex-direction: column;
  }

  #live
    #upComing
    div.upNextShowContainer
    div.showContainer
    div.rightContainer
    div.buttonContainer
    button {
    margin-right: 0;
    width: 67%;
    margin-bottom: 10px;
  }

  #live
    #upComing
    div.upNextShowContainer
    div.showContainer
    div.rightContainer
    div.buttonContainer
    button:last-child {
    margin-bottom: 0;
  }

  #live
    #allShows
    div.showsContainer
    div.shows
    div.showContainer
    div.rightContainer
    div.buttonContainer {
    flex-direction: column;
  }

  #live
    #allShows
    div.showsContainer
    div.shows
    div.showContainer
    div.rightContainer
    div.buttonContainer
    button {
    margin-right: 0;
    margin-bottom: 10px;
    width: 67%;
  }

  #live
    #allShows
    div.showsContainer
    div.shows
    div.showContainer
    div.rightContainer
    div.buttonContainer
    button:last-child {
    margin-bottom: 0;
  }
}

@media all and (max-width: 360px) {
  #live
    #upComing
    div.upNextShowContainer
    div.showContainer
    div.rightContainer
    div.buttonContainer
    button {
    padding: 8px 14px;
  }
}

@media all and (max-width: 320px) {
}
