.no-active{
    display: none;
}
#catchUp{
    padding: 130px 0 0;
}
#catchUp #spotlight { 
    position: relative;
    padding-bottom: 50px;
}
#catchUp #spotlight div.thumbnailContainer { 
    width: 85%;
    background-color: #000;
    cursor: pointer;
    position: relative;
    margin: 0 auto
}
#catchUp #spotlight div.thumbnailContainer img.bg { 
    display: block;
    width: 100%;
    margin: 0 auto;
}
#catchUp #spotlight div.thumbnailContainer img.play-icon{
    width: 68px;
    position: absolute;
    left: 0;
    right: 0;
    margin:auto;
    top: 0;
    bottom: 0;
    
}

#catchUp #spotlight div.thumbnailContainer img.play-icon:hover { 
    -webkit-filter: drop-shadow(0 0 3px #fff);
    filter: drop-shadow(0 0 3px #fff);
    
}
#catchUp #spotlight div.videoContainer { 
    width: 85%;
    margin: 0 auto;
}
#catchUp #spotlight div.videoContainer div.Vplayer { 
    position: relative;
    z-index: 9;
    width: 100%;
    height: 100%;
}
#catchUp #spotlight div.videoContainer div.Vplayer.pipMode{
    position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  width: 22%;
  right: 0;
  z-index: 999999999;
  height: 200px;
}
#catchUp #spotlight div.videoContainer div.Vplayer div.closeIcon { 
    display: none;
}
#catchUp #spotlight div.videoContainer div.Vplayer.pipMode:hover div.closeIcon { 
    display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9;
  background-color: #6f7072;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
#catchUp #spotlight div.videoContainer div.Vplayer.pipMode:hover div.closeIcon svg { 
    stroke: #fff !important;
    width: 10px !important;
    height: 10px !important;
}
#catchUp #spotlight div.videoContainer video { 
    width: 100%;
    height: 100%;
    display: block;
}
#catchUp #spotlight div.daysButtonContainer {
    margin-top: 40px;
}
#catchUp #spotlight div.daysButtonContainer button {
    background-color: var(--button-color);
    padding: 8px 30px;
    border: 2px solid var(--white-color);
    border-radius: 30px;
    color: var(--white-color);
    font-size: 16px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    width: 20%;
    margin-right: 30px;
}
#catchUp #spotlight div.daysButtonContainer button.activeButton{
    background-color: var(--active-color);
}
#catchUp #spotlight div.daysButtonContainer button.activeButton:hover{
    background-color: var(--active-color);
}
#catchUp #spotlight div.daysButtonContainer button:last-child {
    margin-right: 0;
}
#catchUp #spotlight div.daysButtonContainer button:hover {
    background-color: #213a64
}

#catchUp #allShows { 
    /* background: url("../../assets/images/comingup-gradient-live.png") repeat-x top center;
    background-size: cover; */
    background:transparent linear-gradient(180deg, #001332 0%, #00191500 10%) 0% 0% no-repeat padding-box;

    color: #fff;
    padding: 15px 0 30px;
}
#catchUp #allShows div.showsContainer { 

}
#catchUp #allShows div.showsContainer div.shows { 
    margin-bottom: 30px;
}
#catchUp #allShows div.showsContainer div.shows:last-child { 
    margin-bottom: 0;
}
#catchUp #allShows div.showsContainer div.shows h3 { 
    font-size: 22px;
    color: var(--text-color) !important;
}
#catchUp #allShows div.showsContainer div.shows div.showContainer { 
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#catchUp #allShows div.showsContainer div.shows div.showContainer div.leftContainer { 
    width: 28%;
}
#catchUp #allShows div.showsContainer div.shows div.showContainer div.leftContainer div.imageContainer { 
    width: 100%;
}
#catchUp #allShows div.showsContainer div.shows div.showContainer div.leftContainer div.imageContainer img { 
    width: 100%;
    border-radius: 10px;
    display: block;
}
#catchUp #allShows div.showsContainer div.shows div.showContainer div.rightContainer { 
    width: 70%;
}
#catchUp #allShows div.showsContainer div.shows div.showContainer div.rightContainer h1.title { 
    margin-bottom: 10px;
    font-size: 24px;
}
#catchUp #allShows div.showsContainer div.shows div.showContainer div.rightContainer p.description { 
    margin-bottom: 20px;
    max-width: 60%;
}
#catchUp #allShows div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer { 
    
}
#catchUp #allShows div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button { 
    background-color: var(--button-color);
    padding: 8px 40px;
    border: 2px solid #fff;
    border-radius: 30px;
    color: #fff;
    font-size: 16px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    display: flex;
    align-items: center;
}
#catchUp #allShows div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button img { 
    margin-left: 10px;
    width: 12px;
}
#catchUp #allShows div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button:hover { 
    background-color: #213a64;
}
#catchUp #allShows div.bottom {
    background: url("../../assets//images//shedule-gradient.png") repeat-x center bottom;
    background-size: contain;
    position: absolute;
    left: 0;
    bottom: 26px;
    width: 100%;
    height: 113px;
}
#catchUp #allShows div.bottom div.downArrowContainer {
    width: 55px;
    margin: 63px auto 0;
    cursor: pointer;
}
#catchUp #allShows div.bottom div.downArrowContainer img {
    display: block;
    width: 100%;
}

/* Responsive Section */

@media all and (max-width:1280px){
    #catchUp #allShows div.showsContainer div.shows div.showContainer div.rightContainer p.description {
        max-width: 90%;
    }
    #catchUp #spotlight div.daysButtonContainer button {
        width: 23%;
    }
}
@media all and (max-width:980px){
    #catchUp #spotlight div.videoContainer div.Vplayer.pipMode{
    width: 33%;

    }
    #catchUp #allShows div.showsContainer div.shows div.showContainer div.leftContainer {
        width: 44%;
    }
    #catchUp #allShows div.showsContainer div.shows div.showContainer div.rightContainer {
        width: 54%;
    }
    #catchUp #allShows div.showsContainer div.shows div.showContainer div.rightContainer p.description {
        max-width: 100%;
    }
    #catchUp #spotlight div.daysButtonContainer {
        margin-top: 40px;
        overflow-x: scroll;
        white-space: nowrap;
        padding-bottom: 31px;
    }
    #catchUp #spotlight div.daysButtonContainer button {       
        width: 38%;
    }
}
@media all and (max-width:768px){
    #catchUp #allShows div.showsContainer div.shows div.showContainer {
        flex-direction: column;
    }
    #catchUp #allShows div.showsContainer div.shows div.showContainer div.leftContainer {
        width: 100%;
    }
    #catchUp #allShows div.showsContainer div.shows div.showContainer div.rightContainer {
        width: 100%;
        margin-top: 30px;
    }
    .wrapper {
        width: 96%;
        margin: 0 auto;
    }
    #catchUp #spotlight div.thumbnailContainer img.bg {
        width: 100%;
    }
}
@media all and (max-width:640px){
    #catchUp #spotlight div.videoContainer div.Vplayer.pipMode{
        width: 49%;
    
        }
    #catchUp #spotlight div.thumbnailContainer img.play-icon {
        width: 45px;
    }
}
@media all and (max-width:480px){
    #catchUp #allShows div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button {
        margin-bottom: 10px;
    }
    #catchUp #allShows div.showsContainer div.shows div.showContainer div.rightContainer div.buttonContainer button:last-child {
        margin-bottom: 0;
    }
    #catchUp #spotlight div.daysButtonContainer button {
        width: 61%;
    }
}
@media all and (max-width:360px){
    
}
@media all and (max-width:320px){
    
}