#privacyPolicy {
    padding: 150px 0 0;
    color: #fff;;
}
#privacyPolicy h1 {
    text-align: center;
    margin-bottom: 30px;
}
#privacyPolicy div.content {
    background: url("../../assets/images/userspage-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0;
    min-height: 500px;
    background-position-x: 49%;
}
#privacyPolicy div.content div.wrapper {
    width: 75%;
    margin: 0 auto;
}
#privacyPolicy div.content div.wrapper p  {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.4em;
}
#privacyPolicy div.content div.wrapper h3  {
    font-size: 20px;
    margin-bottom: 10px;
}
#privacyPolicy div.content div.wrapper p.end  {
    margin-bottom: 30px;
}
#privacyPolicy div.content div.wrapper div.listSection {
    margin-bottom: 30px;
}
#privacyPolicy div.content div.wrapper div.listSection p  {
    margin-bottom: 20px;
}
#privacyPolicy div.content div.wrapper div.listSection ul  {
    padding-left: 20px;
}
#privacyPolicy div.content div.wrapper div.listSection ul li  {
       list-style: decimal;
       font-size: 16px;
       margin-bottom: 5px;
       line-height: 1.4em;
}


/* Responsive section  */


@media all and (max-width:1280px){
    #privacyPolicy div.content {
        background-position-x: 51%;
    }
    #privacyPolicy div.content div.wrapper {
        width: 90%;
    }
    #privacyPolicy div.content div.wrapper p {
        font-size: 15px;
    }
}
@media all and (max-width:980px){
    
}
@media all and (max-width:768px){
    
}
@media all and (max-width:640px){
    
}
@media all and (max-width:480px){
    #privacyPolicy div.content {
        background-position-x: 52%;
    }
}
@media all and (max-width:360px){
    
}
@media all and (max-width:320px){
    
}