#singlePage .slick-dots {
  position: absolute;
  bottom: 10px !important;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
#singlePage .slick-dots li.slick-active button:before {
  opacity: 0.75 !important;
  color: #fff !important;
}
#singlePage .slick-dots li button:before {
  font-size: 14px !important;
}
#singlePage .slick-dots li button:before {
  opacity: 0.25 !important;
  color: #fff !important;
}
#singlePage .slick-prev {
  left: -10px !important;
  z-index: 9 !important;
}
#singlePage .slick-next {
  z-index: 9 !important;
  right: -10px !important;
}

#singlePage {
  padding: 100px 0 0;
}
#singlePage #features {
  margin-top: 30px;
}
#singlePage #features .items {
  /* background-image: url(https://www.kicctv.tv/images/featured-gradient.png),
    url("../../../assets/images/blur\ overlay.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: cover;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 50px 50px;
}
#singlePage #features div.featureSection {
  position: relative;
  padding: 67px 50px;
  overflow: hidden;
}
#singlePage #features div.featureSection div.background {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  filter: blur(4px);
  overflow: hidden;
}
#singlePage #features div.featureSection div.background img {
  display: block;
  width: 100%;
}
#singlePage #features div.featureSection div.containers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#singlePage #features div.featureSection div.containers div.leftContainer {
  width: 45%;
}
#singlePage
  #features
  div.featureSection
  div.containers
  div.leftContainer
  div.imageContainer {
  display: block;
  max-width: 100%;
  height: auto;
}
#singlePage
  #features
  div.featureSection
  div.containers
  div.leftContainer
  div.imageContainer
  img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  border-radius: 10px;
}
#singlePage #features div.featureSection div.containers div.rightContainer {
  width: 50%;
}
#singlePage
  #features
  div.featureSection
  div.containers
  div.rightContainer
  h1.title {
  color: #fbfbfb;
  font-size: 28px;
  margin-bottom: 18px;
}
#singlePage #features div.featureSection div.containers div.rightContainer p {
  color: var(--white-color) !important;
}
#singlePage
  #features
  div.featureSection
  div.containers
  div.rightContainer
  div.buttonContainer {
  margin-top: 30px;
  display: flex;
}
#singlePage
  #features
  div.featureSection
  div.containers
  div.rightContainer
  div.buttonContainer
  button {
  margin-right: 30px;
  background-color: #000;
  padding: 8px 26px 8px 0;
  border: 2px solid #fff;
  border-radius: 30px;
  color: #e3e3e3;
  font-size: 16px;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 10rem;
  justify-content: right;
}
#singlePage
  #features
  div.featureSection
  div.containers
  div.rightContainer
  div.buttonContainer
  button:first-child div {
  display: flex;
  align-items: center;
}
#singlePage
  #features
  div.featureSection
  div.containers
  div.rightContainer
  div.buttonContainer
  button:hover{
    background-color: #3b66af;
  }
#singlePage
  #features
  div.featureSection
  div.containers
  div.rightContainer
  div.buttonContainer
  button
  div.list {
  display: flex;
  align-items: center;
}
#singlePage
  #features
  div.featureSection
  div.containers
  div.rightContainer
  div.buttonContainer
  button
  div
  img {
  margin-left: 10px;
  width: 12px;
}
#singlePage
  #features
  div.featureSection
  div.containers
  div.rightContainer
  div.buttonContainer
  button:last-child {
  margin-right: 0;
  position: relative;
}
#singlePage
  #features
  div.featureSection
  div.containers
  div.rightContainer
  div.buttonContainer
  button:last-child
  img {
  margin-left: 20px;
  width: 12px;
}
#singlePage
  #features
  div.featureSection
  div.containers
  div.rightContainer
  div.buttonContainer
  button:last-child
  div.shareContainer {
  position: absolute;
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  padding: 6px;
  z-index: 99;
}
#singlePage
  #features
  div.featureSection
  div.containers
  div.rightContainer
  div.buttonContainer
  button:last-child
  div.shareContainer.active {
  display: block;
  /* animation: slideOneByOne 0.6s ease-in-out; */

}
#singlePage
  #features
  div.featureSection
  div.containers
  div.rightContainer
  div.buttonContainer
  button:last-child
  div.shareContainer.no-active {
  display: none;
}
#singlePage
  #features
  div.featureSection
  div.containers
  div.rightContainer
  div.buttonContainer
  button:last-child
  div.shareContainer
  div.facebook {
  border-radius: 6px;
  background-color: #3b5998;
  margin-bottom: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* animation: slideOneByOne 0.4s ease-in-out; */
}
#singlePage
  #features
  div.featureSection
  div.containers
  div.rightContainer
  div.buttonContainer
  button:last-child
  div.shareContainer
  div.twitter {
  border-radius: 6px;
  background-color: #00aced;
  margin-bottom: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* animation: slideOneByOne 0.5s ease-in-out; */

}
#singlePage
  #features
  div.featureSection
  div.containers
  div.rightContainer
  div.buttonContainer
  button:last-child
  div.shareContainer
  div.whatsapp {
  border-radius: 6px;
  background-color: #25d366;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* animation: slideOneByOne 0.6s ease-in-out; */

}
#singlePage
  #features
  div.featureSection
  div.containers
  div.rightContainer div.buttonContainer div.shareContainer {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-animation: slideDown 0.4s ease-in-out;
          animation: slideDown 0.4s ease-in-out;
}
#singlePage #features div.featureSection div.gradientBg {
  bottom: 0 !important;
}
#singlePage #features .items div.leftContainer {
  width: 48%;
}
#singlePage #features .items div.leftContainer div.imageContainer {
  width: 88%;
}
#singlePage #features .items div.leftContainer div.imageContainer img {
  width: 100%;
  display: block;
  z-index: 9;
  border-radius: 10px;
}
#singlePage #features .items div.rightContainer {
  width: 52%;
}
#singlePage #features .items div.rightContainer h1.heading {
  color: #fff;
  font-size: 25px;
  margin-bottom: 20px;
}
#singlePage #features .items div.rightContainer p {
  line-height: 1.5em;
  color: #fff;
}
#singlePage #features .items div.rightContainer div.buttonContainer {
  margin-top: 30px;
  display: flex;
}
#singlePage #features .items div.rightContainer div.buttonContainer button {
  margin-right: 30px;
  background-color: #000;
  padding: 8px 50px;
  border: 2px solid #fff;
  border-radius: 30px;
  color: #fff;
  font-size: 18px;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
}
#singlePage
  #features
  .items
  div.rightContainer
  div.buttonContainer
  button:hover {
  background-color: #213a64;
}
#singlePage
  #features
  .items
  div.rightContainer
  div.buttonContainer
  button:last-child {
  margin-right: 0;
  padding: 8px 25px;
}
#singlePage #features .items div.rightContainer div.buttonContainer button img {
  margin-left: 20px;
  width: 12px;
}

#singlePage #shows {
  color: #fff;
  position: relative;
  padding: 40px 0 0;
}
.no-active {
  display: none;
}
#singlePage #shows h1 {
  font-size: 25px;
}
#singlePage #shows ul {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}
#singlePage #shows ul li {
  width: 23%;
  margin-bottom: 20px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  margin-right: 20px;
}
#singlePage #shows ul li:nth-child(4n) {
  margin-right: 0;
}
#singlePage #shows ul li:hover {
  transform: scale(1.1);
}
#singlePage #shows ul li div.imageContainer {
  width: 100%;
}
#singlePage #shows ul li div.imageContainer img {
  display: block;
  width: 100%;
  border-radius: 10px;
}
#singlePage #shows ul li span {
  margin-top: 10px;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}
#singlePage #shows ul li div.descriptionContainer {
  background: url(../../../assets/images/bg-header.png);
  width: 100%;
  padding: 8px 10px;
  box-sizing: border-box;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute; 
  left: 0;
  visibility: hidden; 
  opacity: 0; 
  transition: visibility 0.3s ease, opacity 0.3s ease;
  z-index: 1; 
  top: 80%;
}

#singlePage  #shows ul li:hover div.descriptionContainer {
  visibility: visible; 
  opacity: 1; 
}
#singlePage #shows ul li div.descriptionContainer p {
  font-size: 12px;
  color: var(--text-color);
  margin: 0;
  display: -webkit-box; 
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; 
  text-overflow: ellipsis;

}

#singlePage #shows div.bottom {
  background: url("../../../assets//images/shedule-gradient.png") repeat-x
    center bottom;
  background-size: contain;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 109px;
}
#singlePage #shows div.bottom div.downArrowContainer {
  width: 55px;
  margin: 0 auto;
  cursor: pointer;
}
#singlePage #shows div.bottom div.downArrowContainer img {
  display: block;
  width: 100%;
}

/* Responsive section */
@media all and (max-width: 1400px) {
  #singlePage #shows ul li div.descriptionContainer {
    margin-top: -1%;
  } 
}
@media all and (max-width: 1200px) {
  #singlePage #features div.featureSection {
    padding: 136px 50px;
  }
  
  #singlePage #features div.featureSection div.containers div.leftContainer {
    width: 43%;
  }
  #singlePage #features div.featureSection div.containers div.rightContainer {
    width: 54%;
  }
  #singlePage
    #features
    div.featureSection
    div.containers
    div.rightContainer
    h1.title {
    font-size: 26px;
    margin-bottom: -1px;
  }
  #singlePage
    #features
    div.featureSection
    div.containers
    div.rightContainer
    div.buttonContainer {
    margin-top: 11px;
  }
  #singlePage #features div.featureSection div.containers div.rightContainer p {
    color: #fbfbfb;
    font-size: 13px;
  }
  #singlePage
    #features
    div.featureSection
    div.containers
    div.rightContainer
    div.buttonContainer
    button {
    padding: 5px 26px 5px 0;
  }
  #singlePage #shows ul li div.descriptionContainer {
    margin-top: -4%;
  } 
}
@media all and (max-width: 1024px) {
}
@media all and (max-width: 980px) {
  #singlePage #features .items div.rightContainer div.buttonContainer {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  #singlePage #features .items div.rightContainer div.buttonContainer button {
    margin-right: 0;
    width: 180px;
    margin-bottom: 10px;
  }
  #singlePage
    #features
    .items
    div.rightContainer
    div.buttonContainer
    button:last-child {
    margin-bottom: 0;
  }
  #singlePage #features .items div.rightContainer {
    width: 50%;
  }
  #singlePage #features .items div.rightContainer {
    width: 50%;
  }
  #singlePage #shows ul li {
    width: 30%;
  }
  #singlePage #shows ul li:nth-child(4n) {
    margin-right: 20px;
  }
  #singlePage #shows ul li:nth-child(3n) {
    margin-right: 0;
  }
  #singlePage #features div.featureSection {
    padding: 83px 50px 148px;
  }
  #singlePage #features div.featureSection div.background img {
    height: 100%;
    object-fit: cover;
  }
  #singlePage #features div.featureSection div.containers div.leftContainer {
    width: 100%;
    margin-bottom: 40px;
  }
  #singlePage #features div.featureSection div.containers div.rightContainer {
    width: 100%;
  }
  #singlePage #features div.featureSection div.containers {
    flex-direction: column;
  }
  #singlePage
    #features
    div.featureSection
    div.containers
    div.rightContainer
    h1.title {
    margin-bottom: 10px;
  }
  
}
@media all and (max-width: 768px) {
  #singlePage #features .items {
    flex-direction: column;
    padding: 45px 50px 165px;
  }
  #singlePage #features .items div.leftContainer {
    width: 100%;
    margin-bottom: 23px;
  }
  #singlePage #features .items div.rightContainer {
    width: 100%;
  }
  #singlePage #features .items div.rightContainer div.buttonContainer {
    flex-direction: row;
  }
  #singlePage #features .items div.rightContainer div.buttonContainer button {
    margin-right: 10px;
    margin-bottom: 0;
  }
  #singlePage
    #features
    .items
    div.rightContainer
    div.buttonContainer
    button:last-child {
    margin-right: 0;
  }
  #singlePage #ck-dots {
    bottom: 16px !important;
  }
  #singlePage #shows ul li {
    width: 46%;
  }
  #singlePage #shows ul li:nth-child(3n) {
    margin-right: 20px;
  }
  #singlePage #shows ul li:nth-child(2n) {
    margin-right: 0;
  }
 
}
@media all and (max-width: 640px) {
  #singlePage #features .items div.rightContainer div.buttonContainer button {
    padding: 9px 27px 9px 62px;
    font-size: 19px;
    width: 280px;
    justify-content: space-between;
  }
  #singlePage
    #features
    .items
    div.rightContainer
    div.buttonContainer
    button
    img {
    margin-left: 10px;
  }
  #singlePage #features .items {
    padding: 98px 29px;
  }
  #singlePage .slick-prev {
    left: -3px !important;
  }
  #singlePage #shows ul li {
    width: 100%;
    margin-right: 0;
  }
  #singlePage #shows ul li div.descriptionContainer p{
    padding-left: 4%;
    padding-right: 6%;
  }
  #singlePage #shows ul li:nth-child(3n) {
    margin-right: 0;
  }
 
  #singlePage #features div.featureSection div.containers div.rightContainer div.buttonContainer button.removeList {
    width: 76% !important;
  }
}
@media all and (max-width: 480px) {
  #singlePage #features .items div.rightContainer div.buttonContainer {
    flex-direction: column;
  }
  #singlePage #features .items div.rightContainer div.buttonContainer button {
    margin-right: 0;
    width: 166px;
    padding: 9px 27px 9px 56px;
    justify-content: space-between;
  }
  #singlePage
    #features
    .items
    div.rightContainer
    div.buttonContainer
    button:last-child {
    margin-top: 10px;
  }
  #singlePage #features div.featureSection {
    padding: 57px 28px 148px;
  }
  #singlePage #features div.featureSection div.containers div.rightContainer div.buttonContainer {
    flex-direction: column;
}
#singlePage #features div.featureSection div.containers div.rightContainer div.buttonContainer button.share {
  
  margin-top: 13px;
}
#singlePage #features div.featureSection div.containers div.rightContainer div.buttonContainer button {
  width: 57%;
}
}
@media all and (max-width: 360px) {
}

@keyframes slideOneByOne{
  0%{
    transform: translateY(-20px);
  }
  100%{
    transform: translateY(0);
  }
}
