#searchPage{
    padding: 150px 0;
    overflow-x: hidden;
}
#searchPage h1{
    font-size: 24px;
    color: #fff;
    margin-bottom: 20px;
    padding-left: 15px;
}
#searchPage div.searchDataContainer{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 98%;
        margin: 0 auto;
}
#searchPage div.searchDataContainer.shows{
   margin-bottom: 30px;
}
#searchPage div.searchDataContainer div.item{
    width: 32%;
    margin-right: 25px;
    margin-bottom: 30px;
    transition: 0.4s ease-in-out;
    cursor: pointer;
}
#searchPage div.searchDataContainer div.item:hover{
    transform: scale(1.1);
}
#searchPage div.searchDataContainer div.item:nth-child(3n){
    margin-right: 0;
}
#searchPage div.searchDataContainer div.item div.imageContainer{
    width: 100%;
    display: block
}
#searchPage div.searchDataContainer div.item img{
    width: 100%;
    display: block;
    border-radius:8px
}
#searchPage div.searchDataContainer div.item h1.title{
    font-size: 16px;
    color: #fff;
    margin-top: 10px;
    font-weight: 500;
    padding-left: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media all and (max-width:1400px){
    #searchPage div.searchDataContainer div.item {
        width: 32%;
        margin-right: 22px;
    }
}
@media all and (max-width:1200px){
    #searchPage div.searchDataContainer div.item {
        width: 32%;
        margin-right: 17px;
    }
}
@media all and (max-width:980px){
    #searchPage div.searchDataContainer div.item {
        width: 31%;
        margin-right: 25px;
    }
}
@media all and (max-width:769px){
    #searchPage div.searchDataContainer div.item {
        width: 48%;
        margin-right: 20px;
    }
    #searchPage div.searchDataContainer div.item:nth-child(2n) {
        margin-right: 0 !important;
    }
    #searchPage div.searchDataContainer div.item:nth-child(3n) {
        margin-right: 20px;
    }
}
@media all and (max-width:640px){
    #searchPage div.searchDataContainer div.item {
        width: 100%;
        margin:0 !important;
    }
}